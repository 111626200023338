import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { withTranslation } from 'react-i18next';
import { SocialIcon } from 'react-social-icons';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import EventHeader from 'components/EventHeader';
import DocumentTitle from 'components/DocumentTitle';
import Breadcrumbs from 'components/Breadcrumbs';
import Header from 'components/Header';
import Footer from 'components/Footer';
import FullScreenLoader from 'components/Loader/FullScreenLoader';

import './OrderConfirmed.scss';
import ToastContent from 'components/ToastContent';
import { logError } from 'services/error-handling/ErrorHandler';
import { handleInternalApiCall, METHOD_GET } from 'services/api-requests/ApiCallHandler';
import LocalStorageService from 'services/storage/LocalStorageService';
import { trackPurchase } from 'services/tracking/TrackingHandler';
import { generatePath, Redirect } from 'react-router-dom';

class OrderConfirmed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: null,
      order: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    const { t, match } = this.props;

    handleInternalApiCall(`/shop/order/${match.params.orderSlug}`,
      METHOD_GET,
      false,
      null,
      (response) => {
        this.setState({
          event: response.data.event,
          order: response.data.order,
          isLoading: false,
        });
      },
      (error) => {
        logError(error);
        toast.error(<ToastContent type="error" message={t('error.order.get')} />);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { i18n, t, match } = this.props;
    const { event, order, isLoading } = this.state;

    if (isLoading) {
      return <FullScreenLoader route={match} />;
    }

    if (order.cartUuid === LocalStorageService.getCartUuid()) {
      LocalStorageService.clearCartUuid();
      window.location.reload();
      return <FullScreenLoader route={match} />;
    }

    if (order.status !== 'paid') {
      return (
        <Redirect
          to={`/${i18n.language}${generatePath(t('routes:ticket.order_pay'),
            { orderSlug: match.params.orderSlug })}`}
        />
      );
    }

    const gifs = [
      'https://media.giphy.com/media/Is1O1TWV0LEJi/giphy.gif',
      'https://media.giphy.com/media/nGzeO4uSxRUcg/giphy.gif',
      'https://media.giphy.com/media/12UlfHpF05ielO/giphy.gif',
    ];

    const gif = gifs[Math.floor(Math.random() * gifs.length)];

    const message = `${t('order.event.invite.message_1')} ${event.name}! ${t(
      'order.event.invite.message_2',
    )} ${event.websiteUrl}`;
    const whatsapp = 'https://wa.me/?text=';
    const encodedWhatsAppUrl = whatsapp + encodeURI(message);

    trackPurchase(order);

    return (
      <div className="OrderConfirmed">
        <DocumentTitle
          title={`Soul Survivor ${event.name} | ${t('order.event.confirmed.confirmed')}`}
        />

        <Header route={match} />

        <div className="Main">
          <EventHeader
            name={event.name}
            description={event.description}
            date={event.date}
            fromDate={event.startDateTime}
            tillDate={event.endDateTime}
            venue={event.location}
            tickets={event.tickets}
            small
            websiteUrl={event.websiteUrl}
            mapsUrl={event.mapsUrl}
          />

          <Container>
            <Breadcrumbs
              active="thanks"
              eventSlug={event.slug}
              eventUrl={event.url}
            />

            <Row>
              <Col lg={7} className="CheckOutColumn">
                <div className="DetailsBlock Block">
                  <h2>
                    {t('order.event.confirmed.title')}
                    {' '}
                    {event.name}
                    !
                  </h2>
                  <div className="BlockExplanation">
                    {t('order.event.confirmed.subtitle')}
                  </div>

                  <div className="ThanksGif">
                    <img src={gif} alt="" />
                  </div>

                  {t('order.event.confirmed.description')}
                  <br />
                  {t('order.event.confirmed.order_number')}
                  <strong>{order.orderNumber}</strong>
                  .
                  <br />
                  {t('order.event.confirmed.email')}
                </div>
              </Col>
              <Col lg={5} className="CheckOutColumn">
                {(event.facebookUrl || event.websiteUrl)
                && (
                  <div className="DetailsBlock ContactMethodsBlock Block">
                    <h2>{t('order.event.invite.title')}</h2>

                    <div>
                      {event.name}
                      {' '}
                      {t('order.event.invite.text')}
                    </div>

                    {event.facebookUrl && (
                      <SocialIcon
                        url={event.facebookUrl}
                        bgColor="#FFFFFF"
                        fgColor="#A9B7B4"
                        style={{ height: 48, width: 48 }}
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    )}

                    {event.websiteUrl && (
                      <SocialIcon
                        url={encodedWhatsAppUrl}
                        bgColor="#FFFFFF"
                        fgColor="#A9B7B4"
                        style={{ height: 48, width: 48 }}
                        network="whatsapp"
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    )}

                    {event.websiteUrl && (
                      <SocialIcon
                        url={event.websiteUrl}
                        bgColor="#FFFFFF"
                        fgColor="#A9B7B4"
                        style={{ height: 48, width: 48 }}
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    )}
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withTranslation()(OrderConfirmed);

OrderConfirmed.propTypes = {
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      orderSlug: PropTypes.string,
    }).isRequired,
  }),
};

OrderConfirmed.defaultProps = {
  match: null,
};
