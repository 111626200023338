import React from 'react';
import { Route } from 'react-router-dom';
import LocalizedSwitch from 'components/LocalizedSwitch';
import Events from 'pages/Events/Events';
import SingleEvent from 'pages/Events/SingleEvent';
import EventPageWrapper from 'pages/Events/EventPageWrapper';
import Volunteer from 'pages/Events/Volunteer';
import OrderConfirmed from 'pages/Order/OrderConfirmed';
import OrderYouthLeader from 'pages/Order/OrderYouthLeader';
import OrderStart from 'pages/Order/OrderStart';
import Pay from 'pages/Order/Pay';
import PaymentExpired from 'pages/Order/PaymentExpired';
import Login from 'pages/Login';
import AccountChangePassword from 'pages/Account/AccountChangePassword';
import AccountOrders from 'pages/Account/AccountOrders';
import AccountOrdersOrder from 'pages/Account/AccountOrdersOrder';
import AccountTicketPersonalize from 'pages/Account/AccountTicketPersonalize';
import PrivateRoute from 'components/PrivateRoute';
import AccountTickets from 'pages/Account/AccountTickets';

export default () => (
  <LocalizedSwitch>
    <Route exact path="ticket.events" component={Events} />
    <Route exact path="ticket.event" component={SingleEvent} />
    <Route exact path="ticket.cart" component={EventPageWrapper} />
    <Route exact path="ticket.checkout" component={EventPageWrapper} />
    <Route exact path="ticket.overview" component={EventPageWrapper} />
    <Route exact path="ticket.volunteer" component={Volunteer} />
    <Route exact path="ticket.order_confirmed" component={OrderConfirmed} />
    <Route exact path="ticket.order_youthleader" component={OrderYouthLeader} />
    <Route exact path="ticket.order_pay" component={Pay} />
    <Route exact path="ticket.order_expired" component={PaymentExpired} />
    <Route exact path="ticket.order_start" component={OrderStart} />

    {/* Login */}
    <Route exact path="ticket.account_login" component={Login} />

    {/* Account */}
    <PrivateRoute
      exact
      path="ticket.account_change_password"
      component={AccountChangePassword}
    />
    <PrivateRoute
      exact
      path="ticket.account_orders"
      component={AccountOrders}
    />
    <PrivateRoute
      exact
      path="ticket.account_orders_order"
      component={AccountOrdersOrder}
    />
    <PrivateRoute
      exact
      path="ticket.account_tickets"
      component={AccountTickets}
    />

    {/* Personalization */}
    <PrivateRoute
      exact
      path="ticket.account_tickets_event_personalize"
      component={AccountTicketPersonalize}
    />
  </LocalizedSwitch>
);
