import React from 'react';
import { ValidatorComponent } from 'react-form-validator-core';
import Form from 'react-bootstrap/Form';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

import './Input.scss';

class Input extends ValidatorComponent {
  render() {
    const {
      serverError, mask, placeholder, name, onChange, onBlur, value,
      isValid, as, rows, type, autoComplete, autoFocus,
    } = this.props;
    return (
      <Form.Group className="Input">
        {mask ? (
          <MaskedInput
            mask={mask}
            className="form-control"
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            as={as}
            rows={rows}
            type={type}
            autoComplete={autoComplete}
            ref={(r) => {
              this.input = r;
            }}
            autoFocus={autoFocus}
          />
        ) : (
          <Form.Control
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            isValid={isValid}
            as={as}
            rows={rows}
            type={type}
            autoComplete={autoComplete}
            ref={(r) => {
              this.input = r;
            }}
            autoFocus={autoFocus}
          />
        )}
        {this.errorText()}
        {serverError && serverError.map(
          (errorText, index) => (// eslint-disable-next-line react/no-array-index-key
            <Form.Text key={index} className="InputError">
              {errorText}
            </Form.Text>
          ),
        )}
      </Form.Group>
    );
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return (
      <Form.Text className="InputError">
        {this.getErrorMessage()}
      </Form.Text>
    );
  }
}

export default Input;

Input.propTypes = {
  placeholder: PropTypes.string,
  validators: PropTypes.arrayOf(PropTypes.string),
  errorMessages: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  value: PropTypes.string.isRequired,
  serverError: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.bool,
  ]),
  isValid: PropTypes.bool,
  as: PropTypes.string,
  rows: PropTypes.number,
  mask: MaskedInput.propTypes.mask,
  type: PropTypes.string,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
};

Input.defaultProps = {
  placeholder: null,
  validators: [],
  errorMessages: [],
  onBlur: () => {},
  serverError: null,
  isValid: null,
  as: 'input',
  rows: null,
  mask: null,
  type: null,
  autoComplete: null,
  validatorListener: () => {},
  autoFocus: false,
};
