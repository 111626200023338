import React from 'react';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import DateRangeIcon from '@material-ui/icons/DateRange';
import LocationIcon from '@material-ui/icons/LocationOnOutlined';
import Moment from 'react-moment';
import { LinkContainer } from 'react-router-bootstrap';
import { useTranslation } from 'react-i18next';

import './EventTile.scss';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const EventTile = ({
  name, description, slug, heroImage, startDateTime, endDateTime, location,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <Col md={6} className="Event">
      <Card className="EventTile">
        <div className="EventTileHeader">
          <Link to={`/${i18n.language}/${slug}`}>
            <img src={heroImage} alt={name} />
          </Link>
        </div>

        <Card.Body>
          <h3 className="EventName">{name}</h3>

          <div className="EventInformation">
            <div className="EventInformation EventDate">
              <DateRangeIcon className="EventInformationIcon" />
              <Moment date={startDateTime} locale={i18n.language} format="D MMM YYYY" />
              {startDateTime !== endDateTime && (
                <span>
&nbsp;
                  {t('event.date_till')}
                  {' '}
                  <Moment
                    date={endDateTime}
                    locale={i18n.language}
                    format="D MMM YYYY"
                  />
                </span>
              )}
            </div>
            <div className="EventInformation EventVenue">
              <LocationIcon className="EventInformationIcon" />
              {location}
            </div>
          </div>

          <Card.Text className="EventDescription">
            {description}
          </Card.Text>

          <div className="EventTicketButton">
            <LinkContainer to={`/${i18n.language}/${slug}`}>
              <Button variant="primary">{t('event.button.buy_tickets')}</Button>
            </LinkContainer>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default EventTile;

EventTile.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  heroImage: PropTypes.string.isRequired,
  startDateTime: PropTypes.string.isRequired,
  endDateTime: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
};
