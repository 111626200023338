/* eslint-disable class-methods-use-this, no-unused-vars */
import PaymentMethod from 'services/payment/methods/PaymentMethod';

export default class CreditcardPaymentMethod extends PaymentMethod {
  constructor() {
    super();
    this.valid = false;
    this.mollie = null;
  }

  handlePayment(orderSlug, onSuccess, onError) {
    const promise = this.mollie.createToken();

    promise.then(({ token, error }) => {
      if (error) {
        onError(error);
      } else {
        const data = {
          paymentMethod: 'creditcard',
          cardToken: token,
        };

        super.handlePayment(orderSlug, data, onSuccess, onError);
      }
    });

    promise.catch((error) => {
      onError(error);
    });
  }

  setValid(valid) {
    this.valid = valid;
  }

  isValid() {
    return this.valid;
  }

  setMollieObject(mollie) {
    this.mollie = mollie;
  }
}
