import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import DocumentTitle from 'components/DocumentTitle';
import EventTile from 'components/EventTile';
import Header from 'components/Header';
import Footer from 'components/Footer';

import './Events.scss';
import FullScreenLoader from 'components/Loader/FullScreenLoader';
import ToastContent from 'components/ToastContent';
import { logError } from 'services/error-handling/ErrorHandler';
import { handleInternalApiCall, METHOD_GET } from 'services/api-requests/ApiCallHandler';

class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    const { t } = this.props;
    this.setState({
      isLoading: true,
    });

    handleInternalApiCall('/shop/events', METHOD_GET, false, null, (response) => {
      let events = [];
      if (response.data.length !== 0) {
        events = response.data.events;
      }

      this.setState({
        events,
        isLoading: false,
      });
    }, (error) => {
      logError(error);
      toast.error(<ToastContent type="error" message={t('error.events')} />);
      this.setState({ isLoading: false });
    });
  }

  render() {
    const { t, match } = this.props;
    const { isLoading, events } = this.state;

    if (isLoading === true) return <FullScreenLoader route={match} />;

    return (
      <div className="Events">
        <Header route={match} />

        <div className="Main">
          <Container>
            <DocumentTitle title="Soul Survivor | Tickets" />

            <Row>
              {events.length > 0 ? events.map((event) => (
                <EventTile
                  key={`event-${event.id}`}
                  name={event.name}
                  description={event.description}
                  slug={event.slug}
                  heroImage={event.heroImage}
                  startDateTime={event.startDateTime}
                  endDateTime={event.endDateTime}
                  location={event.location}
                />
              )) : <div className="NoResults">{t('event.no_results')}</div>}
            </Row>

          </Container>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withTranslation()(Events);

Events.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
};

Events.defaultProps = {
  match: null,
};
