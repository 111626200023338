const LocalStorageService = (function () {
  let service;

  function getService() {
    if (!service) {
      service = this;
      return service;
    }
    return service;
  }

  function setToken(tokenObj) {
    localStorage.setItem('access_token', tokenObj.access_token);
    localStorage.setItem('refresh_token', tokenObj.refresh_token);
  }

  function setUser(userObj) {
    localStorage.setItem('user', JSON.stringify(userObj));
  }

  function getAccessToken() {
    return localStorage.getItem('access_token');
  }

  function isLoggedIn() {
    return localStorage.getItem('user') !== null;
  }

  function getUser() {
    if (!isLoggedIn()) {
      return false;
    }
    return JSON.parse(localStorage.getItem('user'));
  }

  function handlePasswordChange() {
    const user = getUser();
    user.hasPassword = true;
    setUser(user);
  }

  function getRefreshToken() {
    return localStorage.getItem('refresh_token');
  }

  function clearToken() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user');
  }

  function hasCartUuid() {
    return localStorage.getItem('cart_uuid') !== null;
  }

  function getCartUuid() {
    return localStorage.getItem('cart_uuid');
  }

  function setCartUuid(cartUuid) {
    localStorage.setItem('cart_uuid', cartUuid);
  }

  function clearCartUuid() {
    localStorage.removeItem('cart_uuid');
  }

  return {
    getService,
    setToken,
    getAccessToken,
    getRefreshToken,
    clearToken,
    getUser,
    setUser,
    isLoggedIn,
    hasCartUuid,
    getCartUuid,
    setCartUuid,
    clearCartUuid,
    handlePasswordChange,
  };
}());
export default LocalStorageService;
