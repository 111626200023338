import React from 'react';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import { classNames } from '../../util';

const FormItem = ({
  label, children, hidden, required, className,
}) => (
  <div className={classNames('FormItem', hidden && 'Hidden', className)}>
    <Form.Row>
      <Form.Label className="FormLabel">
        {label}
        {required ? <span className="FormFieldRequiredAsterisk">*</span> : ''}
      </Form.Label>
    </Form.Row>
    <Form.Row>
      {children}
    </Form.Row>
  </div>
);

export default FormItem;

FormItem.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
  hidden: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
};

FormItem.defaultProps = {
  label: null,
  hidden: false,
  required: false,
  className: null,
};
