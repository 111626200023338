import * as types from '../actions/actionTypes';
import initialState from './initialState';
import LocalStorageService from '../services/storage/LocalStorageService';

export default function sessionReducer(state = initialState.session, action) {
  switch (action.type) {
    case types.LOG_IN_SUCCESS:
      return LocalStorageService.isLoggedIn();
    case types.LOG_OUT_SUCCESS:
      return LocalStorageService.isLoggedIn();
    default:
      return state;
  }
}
