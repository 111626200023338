import noAuthAxios from 'axios';

import authAxios from './auth-axios';

export const METHOD_GET = 'get';
export const METHOD_POST = 'post';
export const METHOD_PUT = 'put';
export const METHOD_DELETE = 'delete';

export const handleApiCall = (path, method, authenticationNeeded, data, onSuccess = () => {
}, onError = () => {
}, config = {}) => {
  const host = window.BASE_URL;
  const axios = authenticationNeeded ? authAxios : noAuthAxios;

  const axiosConfig = {
    ...config,
    method,
    url: path,
    baseURL: `${host}/api`,
  };

  if (method === METHOD_GET) {
    axiosConfig.params = data;
  } else if (method === METHOD_POST || method === METHOD_PUT) {
    axiosConfig.data = data;
  }

  axios(axiosConfig)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

export const handleInternalApiCall = (path, method, authenticationNeeded, data,
  onSuccess = () => {
  }, onError = () => {
  }, config = {}) => {
  handleApiCall(`/internal${path}`, method, authenticationNeeded, data, onSuccess, onError, config);
};
