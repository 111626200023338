import React, { Component } from 'react';

import { Router } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';
import LocalizedRouter from 'components/LocalizedRouter';
import TicketRoutes from 'routes/TicketRoutes';
import ScrollToTop from 'components/ScrollToTop';
import 'scss/index.scss';
import './App.scss';
import LocalStorageService from 'services/storage/LocalStorageService';
import { handleInternalApiCall, METHOD_GET } from 'services/api-requests/ApiCallHandler';
import { logError } from 'services/error-handling/ErrorHandler';
import ToastContent from 'components/ToastContent';
import FullScreenLoader from 'components/Loader/FullScreenLoader';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    if (!LocalStorageService.hasCartUuid()) {
      this.getCartUuid();
    } else {
      this.setState({ isLoading: false });
    }
  }

  getCartUuid = () => {
    const { t } = this.props;
    handleInternalApiCall('/shop/cart/uuid', METHOD_GET, false, null, (response) => {
      this.setState({ isLoading: false });
      LocalStorageService.setCartUuid(response.data.cart_uuid);
    }, (error) => {
      logError(error);
      toast.error(<ToastContent type="error" message={t('error.session.get')} />);
      this.setState({ isLoading: false });
    });
  };

  render() {
    const { match } = this.props;
    const { isLoading } = this.state;

    if (isLoading) {
      return <FullScreenLoader route={match} />;
    }

    return (
      <div className="App">
        <LocalizedRouter
          RouterComponent={Router}
          languages={['en', 'nl']}
        >
          <ScrollToTop />
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable={false}
            pauseOnHover
          />
          <TicketRoutes />
        </LocalizedRouter>
      </div>
    );
  }
}

export default App;

App.propTypes = {
  t: PropTypes.func,
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
};

App.defaultProps = {
  t: () => {},
  match: null,
};
