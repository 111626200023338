import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEn from './translations/common/en.json';
import translationNl from './translations/common/nl.json';

import routesEn from './translations/routes/en.json';
import routesNl from './translations/routes/nl.json';

const selectedLanguage = window.LANG;

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: translationEn,
        routes: routesEn,
      },
      nl: {
        translation: translationNl,
        routes: routesNl,
      },
    },
    lng: selectedLanguage,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
