import React from 'react';
import PropTypes from 'prop-types';

const MoneyString = ({ amount, currency }) => {
  const currencies = {
    EUR: {
      symbol: '€',
      name: 'Euro',
      separators: {
        decimal: ',',
        thousands: '.',
      },
    },
  };

  const amountFloat = parseFloat(amount);
  const number = Math.trunc(amountFloat);
  let decimals = (Math.abs(amountFloat) - Math.floor(amountFloat)) * 100;
  if (decimals < 10) decimals += '0';
  const formatted = number + currencies[currency].separators.decimal + decimals;

  return (
    <span className="MoneyString">
      {currencies[currency].symbol}
      {' '}
      {formatted}
    </span>
  );
};

export default MoneyString;

MoneyString.propTypes = {
  amount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  currency: PropTypes.string.isRequired,
};
