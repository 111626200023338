import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Moment from 'react-moment';
import { LinkContainer } from 'react-router-bootstrap';
import { generatePath } from 'react-router-dom';
import PropTypes from 'prop-types';
import MoneyString from 'components/MoneyString';
import './OrderItem.scss';

const OrderItem = ({
  orderNumber, orderToken, totalPrice, status, confirmedAt, eventName, fromDate, tillDate,
}) => {
  const { t, i18n } = useTranslation();

  if (status !== 'paid' && status !== 'completed' && status !== 'shipping') {
    return null;
  }

  return (
    <Row className="OrderItem">
      <Col className="OrderItemEvent" md={3} sm={6}>
        <div className="OrderItemEventName">{eventName}</div>
        <div className="OrderItemEventDate">
          <Moment date={fromDate} locale={i18n.language} format="D MMM YYYY" />
          {fromDate !== tillDate && (
            <span>
              &nbsp;
              {t('event.date_till')}
              {' '}
              <Moment date={tillDate} locale={i18n.language} format="D MMM YYYY" />
            </span>
          )}
        </div>
      </Col>
      <Col className="OrderItemOrderNumber" md={2} sm={6}>
        #
        {orderNumber}
      </Col>
      <Col className="OrderItemConfirmed" md={3} sm={6}>
        <Moment date={confirmedAt} locale={i18n.language} format="D MMM YYYY, H:mm" />
      </Col>
      <Col className="OrderItemAmount" md={1} sm={6}>
        <MoneyString amount={totalPrice.amount} currency={totalPrice.currency} />
      </Col>
      <Col className="OrderItemButton" md={3} sm={12}>
        <LinkContainer
          to={`/${i18n.language}${generatePath(t('routes:ticket.account_orders_order'),
            { orderSlug: orderToken })}`}
        >
          <Button variant="link" className="OrderItemViewOrderButton">
            {t('button.view_order')}
            <ChevronRightIcon />
          </Button>
        </LinkContainer>
      </Col>
    </Row>
  );
};

export default OrderItem;

OrderItem.propTypes = {
  eventName: PropTypes.string.isRequired,
  orderNumber: PropTypes.string.isRequired,
  orderToken: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  confirmedAt: PropTypes.string.isRequired,
  fromDate: PropTypes.string.isRequired,
  tillDate: PropTypes.string.isRequired,
  totalPrice: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
  }).isRequired,
};
