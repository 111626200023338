import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ValidatorForm } from 'react-form-validator-core';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import Input from 'components/Input/index';
import FormItem from 'components/Form/FormItem';
import './Form.scss';
import { handleApiCall, METHOD_POST } from 'services/api-requests/ApiCallHandler';
import * as sessionActions from 'actions/sessionActions';
import { logError } from 'services/error-handling/ErrorHandler';
import ToastContent from 'components/ToastContent';

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        username: '',
        password: '',
      },
      validation: {},
      checkedMethod: false,
      submitDisabled: false,
      requiresPassword: false,
    };
  }

  handleChange = (event, callback = (() => {
  })) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [event.target.name]: event.target.value,
      },
      validation: {
        [event.target.name]: false,
      },
    }, callback);
  };

  handleError = (error) => {
    if ((error.response && error.response.status === 400)
      || (error.response && error.response.status === 401)) {
      if (error.response.data.fields !== undefined) {
        this.setState({
          validation: error.response.data.fields,
        });
      }
      if (error.response.data.message !== undefined) {
        toast.error(<ToastContent
          type="error"
          message={error.response.data.message}
        />);
      }
    }
  };

  checkLoginMethod = (email) => {
    const { t } = this.props;
    handleApiCall('/login/method', METHOD_POST, false, {
      email,
    }, (response) => {
      const hasEmail = response.data.includes('EMAIL');
      const hasPassword = response.data.includes('PASSWORD');

      if (hasEmail && !hasPassword) {
        this.sendEmail(email);
      } else if (hasEmail && hasPassword) {
        this.setState({
          submitDisabled: false,
          checkedMethod: true,
          requiresPassword: true,
        });
      } else {
        toast.error(<ToastContent
          type="error"
          message={t('error.login.inactive')}
        />);
      }
    }, (error) => {
      this.handleError(error);

      this.setState({
        submitDisabled: false,
      });
    });
  };

  sendEmail = (email) => {
    const { onEmailSent, redirectUrl } = this.props;
    handleApiCall('/login/key/request', METHOD_POST, false, {
      email,
      redirectUrl,
    }, () => {
      onEmailSent();
    }, (error) => {
      this.handleError(error);
    });
  };

  handleSubmit = (event) => {
    const { data, checkedMethod } = this.state;
    const { t, actions } = this.props;
    event.preventDefault();

    this.setState({
      submitDisabled: true,
    });

    if (this.form.isFormValid()) {
      if (!checkedMethod) {
        this.checkLoginMethod(data.username);
      } else {
        actions.logInUserByUsernameAndPassword(data.username, data.password, (error) => {
          if (error.response && error.response.status === 401
            && error.response.data.message !== undefined) {
            toast.error(<ToastContent type="error" message={error.response.data.message} />);
          } else {
            logError(error);
            toast.error(<ToastContent type="error" message={t('error.password.post')} />);
          }

          this.setState({
            submitDisabled: false,
          });
        });
      }
    }

    return false;
  };

  render() {
    const { t, i18n, loggedIn } = this.props;

    const {
      data, submitDisabled, validation, requiresPassword, checkedMethod,
    } = this.state;

    const required = t('form.validation.required');
    const invalid = t('form.validation.invalid');

    if (loggedIn) {
      return <Redirect to={`/${i18n.language}`} />;
    }

    return (
      <ValidatorForm
        ref={(node) => {
          this.form = node;
        }}
        onSubmit={this.handleSubmit}
        method="post"
      >
        <FormItem label={t('form.label.email')}>
          <Col md={12}>
            <Input
              placeholder={t('form.label.email')}
              validators={['required', 'isEmail']}
              errorMessages={[required, invalid]}
              name="username"
              onChange={this.handleChange}
              value={data.username}
              serverError={validation.username}
              autoFocus={!checkedMethod}
            />
          </Col>
        </FormItem>
        {requiresPassword ? (
          <div>
            <FormItem label={t('form.label.password')}>
              <Col md={12}>
                <Input
                  placeholder={t('form.label.password')}
                  validators={['required']}
                  errorMessages={[required]}
                  name="password"
                  onChange={this.handleChange}
                  value={data.password}
                  serverError={validation.password}
                  type="password"
                  autoFocus={checkedMethod}
                />
              </Col>
            </FormItem>

            <Row className="Buttons">
              <Col md={6}>
                <Button variant="link" onClick={() => { this.sendEmail(data.username); }}>
                  <MailOutlinedIcon />
                  {' '}
                  {t('button.send_login_email')}
                </Button>
              </Col>
              <Col md={6}>
                <div className="ButtonRight">
                  <Button variant="primary" disabled={submitDisabled} type="submit">
                    {t('button.login')}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <div>
            <Row className="Buttons">
              <Col md={6} />
              <Col md={6}>
                <div className="ButtonRight">
                  <Button variant="primary" disabled={submitDisabled} type="submit">
                    {t('button.continue')}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </ValidatorForm>
    );
  }
}

function mapStateToProps(state) {
  return { loggedIn: state.session };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(sessionActions, dispatch),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LoginForm));

LoginForm.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  onEmailSent: PropTypes.func.isRequired,
  redirectUrl: PropTypes.string.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    logInUserByUsernameAndPassword: PropTypes.func.isRequired,
  }).isRequired,
};
