/* eslint-disable class-methods-use-this, no-unused-vars */
import { handleInternalApiCall, METHOD_POST } from 'services/api-requests/ApiCallHandler';
import { logError } from 'services/error-handling/ErrorHandler';

export default class PaymentMethod {
  handlePayment(orderSlug, data, onSuccess, onError) {
    handleInternalApiCall(`/shop/order/${orderSlug}/pay`,
      METHOD_POST,
      false,
      data,
      (response) => {
        onSuccess(response.data.checkout_url);
      },
      (error) => {
        logError(error);
        onError(error);
      });
  }

  setup() {
  }

  isValid() {
    return false;
  }

  setDetails(details) {
  }

  getDetails() {
    return null;
  }

  handleSubmit(amount, callback) {
    callback();
  }
}
