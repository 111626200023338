import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { withTranslation } from 'react-i18next';
import SubArrowIcon from '@material-ui/icons/SubdirectoryArrowRight';
import PropTypes from 'prop-types';
import MoneyString from 'components/MoneyString/index';
import VoucherRow from 'components/Cart/VoucherRow';
import CartRemoveMessage from './CartRemoveMessage';

class LargeCartItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: props.quantity,
      lastAmount: 1,
      showRemoveConfirmation: false,
      loaded: true,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { quantity, loaded } = this.props;

    if (quantity !== prevProps.quantity || quantity !== prevState.amount) {
      this.setState({ // eslint-disable-line react/no-did-update-set-state
        amount: quantity,
        lastAmount: prevState.amount,
      });
    }

    if (loaded !== prevProps.loaded || loaded !== prevState.loaded) {
      this.setState({ // eslint-disable-line react/no-did-update-set-state
        loaded,
      });
    }
  }

  handleChange = (event) => {
    const changedAmount = event.target.value;
    let { lastAmount } = this.state;
    const { amount } = this.state;
    const { change, token } = this.props;

    if (amount !== '' && amount !== '0') {
      lastAmount = amount;
    }

    if (!Number.isNaN(changedAmount) && changedAmount !== '0') {
      const number = parseInt(changedAmount, 10) || '';

      if (number <= 99) {
        this.setState({
          amount: number,
          lastAmount,
          loaded: false,
        });

        if (changedAmount !== '') {
          change(token, number);
        }
      }
    }

    if (changedAmount === 0) {
      this.setState({
        amount: lastAmount,
      });
    }
  };

  handleBlur = (event) => {
    const { lastAmount } = this.state;
    const amount = event.target.value;

    if (amount === '') {
      this.setState({
        amount: lastAmount,
      });
    }

    if (amount === '0') {
      this.setState({
        amount: lastAmount,
      });
    }
  };

  handleRemove = () => {
    this.setState({
      showRemoveConfirmation: true,
    });
  };

  handleRemoveCancel = () => {
    this.setState({
      showRemoveConfirmation: false,
    });
  };

  handleRemoveConfirm = () => {
    const { remove, token, voucher } = this.props;
    this.setState({
      showRemoveConfirmation: false,
    });

    remove(token, voucher);
  };

  render() {
    const {
      name, varietyName, quantity, originalPrice, unitPrice, discounts, vouchers, t, remove,
    } = this.props;

    const {
      loaded, lastAmount, amount, showRemoveConfirmation,
    } = this.state;

    const hasDiscount = originalPrice.amount !== unitPrice.amount;
    let discountTypes = [];

    if (hasDiscount) {
      discountTypes = discounts.filter((discount) => discount.active);
    }

    const amountDiscount = vouchers.length;
    const amountNoDiscount = quantity - amountDiscount;
    let timesQuantity = quantity;

    if (!loaded && typeof lastAmount !== 'undefined') {
      timesQuantity = lastAmount;
    }

    const priceNoDiscount = {
      amount: parseFloat(unitPrice.amount) * timesQuantity,
      currency: unitPrice.currency,
    };

    return (
      <div className="CartItem">
        <Row className="CartItemDesktop">
          <Col className="CartItemName" xs={6}>
            <div className="CartItemNameTicket">
              {name}
              {discountTypes.length > 0
              && discountTypes.map((discount, index) => (
                <div
                  className="DiscountBadge"
                  id={`discount-${index}`}
                  key={discount.type}
                >
                  {t(`cart.discountTypes.${discount.type.toLowerCase()}`)}
                </div>
              ))}
            </div>
            {varietyName !== null ? <div className="CartItemNameVariety">{varietyName}</div> : ''}

            {vouchers.length > 0
            && (
              <div className="VoucherInformation">
                {vouchers.map((voucher) => (
                  <VoucherRow
                    key={voucher.code}
                    code={voucher.code}
                    name={voucher.name}
                    amount={voucher.amount}
                    remove={remove}
                  />
                ))}
                {amountNoDiscount > 0 && (
                  <div className="VoucherRow OtherTicketRow">
                    <SubArrowIcon />
                    {amountNoDiscount}
                    x
                    {name}
                    {' '}
                    à
                    <span className="VoucherPrice">
                      <MoneyString amount={unitPrice.amount} currency={unitPrice.currency} />
                    </span>
                  </div>
                )}
              </div>
            )}

          </Col>
          <Col className="CartPriceAmount" xs={5}>
            <Row>
              <Col className="CartItemPricePiece" xs={3} />
              <Col className="CartItemPricePiece" xs={3}>
                <MoneyString amount={unitPrice.amount} currency={unitPrice.currency} />
              </Col>
              <Col className="CartItemQuantity" xs={3}>
                <Form.Control
                  className="TicketAmount"
                  type="text"
                  value={amount}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                />
              </Col>
              <Col className="CartItemPriceTotal" xs={3}>
                <MoneyString amount={priceNoDiscount.amount} currency={priceNoDiscount.currency} />
              </Col>
            </Row>
          </Col>
          <Col className="CartItemActions" xs={1}>
            {showRemoveConfirmation
            && (
              <CartRemoveMessage
                quantity={amount}
                cancel={this.handleRemoveCancel}
                confirm={this.handleRemoveConfirm}
                voucher={false}
              />
            )}
            <Button
              variant="link"
              className="RemoveButton"
              onClick={this.handleRemove}
            >
              <DeleteIcon className="RemoveButtonIcon" />
            </Button>
          </Col>
        </Row>
        <Row className="CartItemMobile">
          <Row>
            <Col className="CartTableLabel" xs={4}>
              {t('cart.product')}
            </Col>
            <Col className="CartItemName" xs={8}>
              <div className="CartItemNameTicket">{name}</div>
              {varietyName !== null ? <div className="CartItemNameVariety">{varietyName}</div> : ''}

              {discountTypes.length > 0
              && discountTypes.map((discount, index) => (
                <div
                  className="DiscountBadge"
                  id={`discount-${index}`}
                  key={discount.type}
                >
                  {t(`cart.discountTypes.${discount.type.toLowerCase()}`)}
                </div>
              ))}

              {vouchers.length > 0
              && (
                <div className="VoucherInformation">
                  {vouchers.map((voucher) => (
                    <VoucherRow
                      key={voucher.code}
                      code={voucher.code}
                      name={voucher.name}
                      amount={voucher.amount}
                      remove={remove}
                    />
                  ))}
                  {amountNoDiscount > 0 && (
                    <div className="VoucherRow OtherTicketRow">
                      <SubArrowIcon />
                      {amountNoDiscount}
                      x
                      {name}
                      {' '}
                      à
                      <span className="VoucherPrice">
                        <MoneyString
                          amount={unitPrice.amount}
                          currency={unitPrice.currency}
                        />
                      </span>
                    </div>
                  )}
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col className="CartTableLabel" xs={4}>
              {t('cart.pricePiece')}
            </Col>
            <Col className="CartItemPricePiece" xs={8}>
              <MoneyString amount={unitPrice.amount} currency={unitPrice.currency} />
            </Col>
          </Row>
          <Row>
            <Col className="CartTableLabel" xs={4}>
              {t('cart.quantity')}
            </Col>
            <Col className="CartItemQuantity" xs={6}>
              <Form.Control
                className="TicketAmount"
                type="text"
                value={amount}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
              />
            </Col>
            <Col className="CartItemActions" xs={2}>
              {showRemoveConfirmation
              && (
                <CartRemoveMessage
                  quantity={amount}
                  cancel={this.handleRemoveCancel}
                  confirm={this.handleRemoveConfirm}
                  voucher={false}
                />
              )}
              <Button
                variant="link"
                className="RemoveButton"
                onClick={this.handleRemove}
              >
                <DeleteIcon className="RemoveButtonIcon" />
              </Button>
            </Col>
          </Row>
          <Row className="CartPriceAmount">
            <Col className="CartTableLabel" xs={4}>
              {t('cart.subtotal')}
            </Col>
            <Col className="CartItemPriceTotal" xs={8}>
              <MoneyString amount={priceNoDiscount.amount} currency={priceNoDiscount.currency} />
            </Col>
          </Row>
        </Row>
      </div>
    );
  }
}

export default withTranslation()(LargeCartItem);

LargeCartItem.propTypes = {
  t: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
  change: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
  voucher: PropTypes.bool,
  token: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  varietyName: PropTypes.string.isRequired,
  originalPrice: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
  }).isRequired,
  unitPrice: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
  }).isRequired,
  vouchers: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
  discounts: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    validFrom: PropTypes.string.isRequired,
    validTill: PropTypes.string.isRequired,
    amount: PropTypes.shape({
      currency: PropTypes.string.isRequired,
      amount: PropTypes.string.isRequired,
    }).isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
  })),
};

LargeCartItem.defaultProps = {
  vouchers: [],
  voucher: false,
  discounts: [],
};
