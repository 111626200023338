import React from 'react';
import './Loader.scss';
import PropTypes from 'prop-types';

const Loader = ({ className }) => (
  <div className={`LoadingSpinner${className && ` ${className}`}`}>
    <div />
    <div />
    <div />
  </div>
);

export default Loader;

Loader.propTypes = {
  className: PropTypes.string.isRequired,
};
