import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import { withTranslation } from 'react-i18next';
import { Route } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import EventHeader from 'components/EventHeader';
import Header from 'components/Header';
import Footer from 'components/Footer';
import FullScreenLoader from 'components/Loader/FullScreenLoader';
import LocalizedSwitch from 'components/LocalizedSwitch';
import ToastContent from 'components/ToastContent';
import { logError } from 'services/error-handling/ErrorHandler';
import { handleInternalApiCall, METHOD_GET } from 'services/api-requests/ApiCallHandler';
import Details from '../../Order/Details';
import Overview from '../../Order/Overview';
import CheckoutCart from '../../Order/CheckoutCart';

class EventPageWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    const { t, match } = this.props;

    handleInternalApiCall(`/shop/event/${match.params.eventSlug}/info`,
      METHOD_GET,
      false,
      null,
      (response) => {
        this.setState({
          event: response.data,
          isLoading: false,
        });
      },
      (error) => {
        logError(error);
        toast.error(<ToastContent type="error" message={t('error.event')} />);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { match, children } = this.props;
    const { event, isLoading } = this.state;

    if (isLoading) return <FullScreenLoader route={match} />;

    return (
      <div className="Page">
        <Header route={match} />

        <div className="Main">
          <EventHeader
            name={event.name}
            description={event.description}
            date={event.date}
            fromDate={event.startDateTime}
            tillDate={event.endDateTime}
            venue={event.location}
            tickets={event.tickets}
            small
            websiteUrl={event.websiteUrl}
            mapsUrl={event.mapsUrl}
          />

          <Container>
            {children}

            <LocalizedSwitch>
              <Route
                exact
                path="ticket.cart"
                render={() => (
                  <CheckoutCart
                    match={match}
                    event={event}
                  />
                )}
              />
              <Route
                exact
                path="ticket.checkout"
                render={() => <Details match={match} event={event} />}
              />
              <Route
                exact
                path="ticket.overview"
                render={() => <Overview match={match} event={event} />}
              />
            </LocalizedSwitch>
          </Container>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withTranslation()(EventPageWrapper);

EventPageWrapper.propTypes = {
  t: PropTypes.func.isRequired,
  children: PropTypes.node,
  match: PropTypes.shape({
    params: PropTypes.shape({
      eventSlug: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

EventPageWrapper.defaultProps = {
  children: null,
};
