import React from 'react';
import { Switch } from 'react-router';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

/**
 *
 * @param path can be string, undefined or string array
 * @param i18n
 * @param t
 * @returns Localized string path or path array
 */
export function localizeRoutePath(path, i18n, t) {
  const locale = i18n.language;

  switch (typeof path) {
    case 'undefined':
      return undefined;
    case 'string':
      return `/${locale}${t(path)}`;
    default:
      return path === '*'
        ? path
        : `/${locale}${t(path)}`;
  }
}

const LocalizedSwitch = ({ children }) => {
  /**
   * inject params and formatMessage through hooks, so we can localize the route
   */
  const { t, i18n } = useTranslation('routes');

  /**
   * Apply localization to all routes
   * Also checks if all children elements are <Route /> components
   */
  return (
    <Switch>
      {React.Children.map(children, (child) => (React.isValidElement(child)
        ? React.cloneElement(child, {
          ...child.props,
          path: localizeRoutePath(child.props.path, i18n, t),
        })
        : child))}
    </Switch>
  );
};

export default LocalizedSwitch;

LocalizedSwitch.propTypes = {
  children: PropTypes.node.isRequired,
};
