import React, { Component } from 'react';

import './Cart.scss';
import './LargeCart.scss';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import VoucherIcon from '@material-ui/icons/CardGiftcard';
import { withTranslation } from 'react-i18next';
import { LinkContainer } from 'react-router-bootstrap';
import { generatePath } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import VoucherForm from 'components/Form/VoucherForm';
import Loader from 'components/Loader';
import LargeCartItem from 'components/Cart/LargeCartItem';
import ToastContent from 'components/ToastContent';
import { logError } from 'services/error-handling/ErrorHandler';
import {
  handleInternalApiCall,
  METHOD_GET,
  METHOD_POST,
} from 'services/api-requests/ApiCallHandler';
import LocalStorageService from 'services/storage/LocalStorageService';
import MoneyString from '../MoneyString';

class LargeCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isLoaded: false,
      products: [],
      totalDiscount: { currency: '', amount: '' },
      totalPrice: { currency: '', amount: '' },
      voucher: null,
      showVoucherInput: false,
    };
  }

  componentDidMount() {
    this.loadCart();
  }

  loadCart = () => {
    const { eventToken, onUpdate, t } = this.props;
    this.setState({ isLoading: true });

    handleInternalApiCall(`/shop/cart/${LocalStorageService.getCartUuid()}/event/${eventToken}`,
      METHOD_GET,
      false,
      null,
      (response) => {
        this.setState({
          isLoading: false,
          isLoaded: true,
          products: response.data.products,
          totalDiscount: response.data.totalDiscount,
          totalPrice: response.data.totalPrice,
          voucher: response.data.voucher,
          showVoucherInput: false,
        });

        onUpdate(response.data.employee);
      },
      (error) => {
        logError(error);
        toast.error(<ToastContent type="error" message={t('error.cart.get')} />);
        this.setState({ isLoading: false });
      });
  };

  changeAmount = (item, amount) => {
    const { eventToken, t } = this.props;
    const { products } = this.state;
    for (let i = 0; i < products.length; i += 1) {
      if (products[i].token === item) {
        products[i].quantity = amount;
        break;
      }
    }

    handleInternalApiCall(`/shop/cart/${LocalStorageService.getCartUuid()}/event/${eventToken}/edit/${item}`,
      METHOD_POST,
      false,
      {
        quantity: amount,
      },
      () => {
        this.setState({ isLoading: false });

        this.loadCart();
      },
      (error) => {
        logError(error);
        toast.error(<ToastContent type="error" message={t('error.cart.edit')} />);
        this.setState({ isLoading: false });
      });
  };

  removeItem = (item, voucher) => {
    const { eventToken, t } = this.props;
    this.setState({ isLoading: true });

    if (voucher) {
      handleInternalApiCall(`/shop/cart/${LocalStorageService.getCartUuid()}/event/${eventToken}/voucher/remove/${item}`,
        METHOD_POST,
        false,
        null,
        () => {
          this.setState({ isLoading: false });

          this.loadCart();
        },
        (error) => {
          logError(error);
          toast.error(<ToastContent type="error" message={t('error.cart.edit')} />);
          this.setState({ isLoading: false });
        });
    } else {
      handleInternalApiCall(`/shop/cart/${LocalStorageService.getCartUuid()}/event/${eventToken}/remove/${item}`,
        METHOD_POST,
        false,
        null,
        () => {
          this.setState({ isLoading: false });
          this.loadCart();
        },
        (error) => {
          logError(error);
          toast.error(<ToastContent type="error" message={t('error.cart.edit')} />);
          this.setState({ isLoading: false });
        });
    }
  };

  toggleVoucherClick = () => {
    const { showVoucherInput } = this.state;
    this.setState({
      showVoucherInput: !showVoucherInput,
    });
  };

  render() {
    const {
      eventSlug, eventToken, t, i18n,
    } = this.props;
    const {
      isLoading, products, voucher, showVoucherInput, isLoaded, totalPrice, totalDiscount,
    } = this.state;

    return (
      <div className="LargeCart">
        <h2>{t('cart.title')}</h2>
        {isLoading && <Loader className="CartLoader" />}
        <div className={isLoading ? 'CartInner Loading' : 'CartInner'}>
          {products.length > 0
            ? (
              <div className={isLoading ? 'CartInner Loading' : 'CartInner'}>
                <div className="CartContents">
                  <Row className="CartTableHeader">
                    <Col xs={6}>
                      {t('cart.product')}
                    </Col>
                    <Col xs={5}>
                      <Row>
                        <Col xs={3} />
                        <Col xs={3}>
                          {t('cart.pricePiece')}
                        </Col>
                        <Col xs={3}>
                          {t('cart.quantity')}
                        </Col>
                        <Col xs={3}>
                          {t('cart.subtotal')}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={1} />
                  </Row>

                  {products.map((item) => (
                    <LargeCartItem
                      key={`cartitem-${item.token}`}
                      name={item.name}
                      token={item.token}
                      change={this.changeAmount}
                      remove={this.removeItem}
                      loaded={!isLoading}
                      varietyName={item.varietyName}
                      quantity={item.quantity}
                      originalPrice={item.originalPrice}
                      unitPrice={item.unitPrice}
                      discounts={item.discounts}
                      vouchers={item.vouchers}
                    />
                  ))}
                  {voucher != null && voucher.map((item) => (
                    <LargeCartItem
                      key={`voucheritem-${voucher.code}`}
                      voucher
                      name={item.name}
                      change={this.changeAmount}
                      remove={this.removeItem}
                    />
                  ))}
                </div>
                {totalDiscount.amount > 0 && (
                  <Row className="CartTotalDiscount">
                    <Col sm={6} />
                    <Col sm={5} xs={12}>
                      <Row>
                        <Col sm={6} />
                        <Col className="CartTotalDiscountText" sm={3} xs={8}>
                          <div className="CartTotalDiscountTextTotal">
                            {t('cart.discount')}
                          </div>
                        </Col>
                        <Col className="CartTotalDiscountAmount" sm={3} xs={4}>
                          -
                          {' '}
                          <MoneyString
                            amount={totalDiscount.amount}
                            currency={totalDiscount.currency}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={1} />
                  </Row>
                )}
                <Row className="CartTotal CartTotalTop">
                  <Col sm={6}>
                    <Row>
                      <Col xs={7}>
                        {!showVoucherInput && (
                          <div className="CartVoucherAdd">
                            <Button
                              className="AddVoucherLink"
                              variant="link"
                              onClick={this.toggleVoucherClick}
                            >
                              <VoucherIcon />
                              {' '}
                              {t('cart.addVoucher')}
                            </Button>
                          </div>
                        )}
                        {showVoucherInput && (
                          <VoucherForm
                            loadCart={this.loadCart}
                            eventToken={eventToken}
                            toggleVoucherClick={this.toggleVoucherClick}
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={5} xs={12}>
                    <Row>
                      <Col sm={6} />
                      <Col className="CartTotalText" sm={3} xs={8}>
                        <div className="CartTotalTextTotal">
                          {t('cart.total')}
                          <div className="CartTotalTextDescription">
                            {t('cart.includingVATshort')}
                          </div>
                        </div>
                      </Col>
                      <Col className="CartTotalAmount" sm={3} xs={4}>
                        <MoneyString
                          amount={totalPrice.amount}
                          currency={totalPrice.currency}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={1} />
                </Row>
              </div>
            ) : (
              <div className="CartContents">
                {isLoaded && (
                  <div className="CartEmpty">
                    {t('cart.cart_is_empty')}
                  </div>
                )}
              </div>
            )}
          <Row className="LargeCartButtons">
            <Col xs={6}>
              <LinkContainer to={`/${i18n.language}/${eventSlug}`}>
                <Button className="BackLink BackLinkBottom" variant="link">
                  <ChevronLeftIcon />
                  {' '}
                  {t('button.back_to_tickets')}
                </Button>
              </LinkContainer>
            </Col>
            <Col xs={6} className="ButtonRight">
              {products.length > 0 && (
                <LinkContainer
                  to={`/${i18n.language}${generatePath(t('routes:ticket.checkout'),
                    { eventSlug })}`}
                >
                  <Button variant="primary">
                    {t('checkout.personal.information')}
                  </Button>
                </LinkContainer>
              )}
            </Col>
          </Row>
        </div>

      </div>
    );
  }
}

export default withTranslation()(LargeCart);

LargeCart.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  eventSlug: PropTypes.string.isRequired,
  eventToken: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
};
