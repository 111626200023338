import React from 'react';
import PropTypes from 'prop-types';
import EventTicketVariety from 'components/EventTickets/EventTicketVariety';

const EventTicket = ({
  token, name, varieties, eventSlug, eventToken, cartAmount, trigger,
}) => (
  <div className="EventTicket" id={token}>
    <h2 className="EventTicketName">{name}</h2>

    {varieties.length > 0 ? varieties.map((variety) => {
      let amount = 0;
      if (cartAmount && variety.token in cartAmount) amount = cartAmount[variety.token];

      return (
        <EventTicketVariety
          key={variety.token}
          eventSlug={eventSlug}
          eventToken={eventToken}
          amount={amount}
          trigger={trigger}
          token={variety.token}
          name={variety.name}
          description={variety.description}
          priceIncludingVat={variety.priceIncludingVat}
          discountedPriceIncludingVat={variety.discountedPriceIncludingVat}
          discounts={variety.discounts}
          openByDefault={variety.openByDefault}
        />
      );
    }) : ''}
  </div>
);

export default EventTicket;

EventTicket.propTypes = {
  token: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  eventSlug: PropTypes.string.isRequired,
  eventToken: PropTypes.string.isRequired,
  cartAmount: PropTypes.objectOf(PropTypes.number).isRequired,
  varieties: PropTypes.arrayOf(PropTypes.shape({
    token: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    capacity: PropTypes.number,
    priceIncludingVat: PropTypes.shape({
      currency: PropTypes.string.isRequired,
      amount: PropTypes.string.isRequired,
    }).isRequired,
    discountedPriceIncludingVat: PropTypes.shape({
      currency: PropTypes.string.isRequired,
      amount: PropTypes.string.isRequired,
    }),
    validFrom: PropTypes.string.isRequired,
    validTill: PropTypes.string.isRequired,
    openByDefault: PropTypes.bool.isRequired,
    discounts: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired,
      validFrom: PropTypes.string.isRequired,
      validTill: PropTypes.string.isRequired,
      amount: PropTypes.shape({
        currency: PropTypes.string.isRequired,
        amount: PropTypes.string.isRequired,
      }).isRequired,
      min: PropTypes.number,
      max: PropTypes.number,
    })),
  })).isRequired,
  trigger: PropTypes.func.isRequired,
};
