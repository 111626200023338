import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import VoucherIcon from '@material-ui/icons/CardGiftcard';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MoneyString from 'components/MoneyString';

const CartItem = ({
  name, varietyName, quantity, originalPrice, unitPrice, totalPrice, discounts, vouchers,
}) => {
  const { t } = useTranslation();

  const hasDiscount = originalPrice.amount !== unitPrice.amount;
  let discountTypes = [];

  if (hasDiscount) {
    discountTypes = discounts.filter((discount) => discount.active);
  }

  const originalTotalPrice = originalPrice.amount * quantity;
  const amountDiscount = vouchers.length;
  const amountNoDiscount = quantity - amountDiscount;
  const priceNoDiscount = {
    amount: parseFloat(unitPrice.amount) * amountNoDiscount,
    currency: unitPrice.currency,
  };

  return (
    <div className="CartItem">
      <Row>
        <Col className="CartItemQuantity" xs={1}>
          {quantity}
          x
        </Col>
        <Col className="CartItemName" xs={7}>
          <div className="CartItemNameTicket">{name}</div>
          {varietyName !== null ? <div className="CartItemNameVariety">{varietyName}</div> : ''}

          {discountTypes.length > 0
          && (
            <div className="DiscountInformation">
              {discountTypes.map((discount, index) => (
                <div
                  className="DiscountBadge"
                  id={`discount-${index}`}
                  key={discount.type}
                >
                  {t(`cart.discountTypes.${discount.type.toLowerCase()}`)}
                </div>
              ))}
            </div>
          )}
        </Col>
        <Col className="CartItemPrice" xs={4}>
          {discountTypes.length > 0
            ? (
              <div className="DiscountedPrice">
                <div className="CurrentPrice">
                  <MoneyString
                    amount={totalPrice.amount}
                    currency={totalPrice.currency}
                  />
                </div>
                <div className="OldPrice">
                  <MoneyString
                    amount={originalTotalPrice}
                    currency={totalPrice.currency}
                  />
                </div>

              </div>
            ) : <MoneyString amount={totalPrice.amount} currency={totalPrice.currency} />}
        </Col>
      </Row>
      {vouchers.length > 0
      && (
        <div className="VoucherInformation">
          {vouchers.map((voucher) => (
            <Row className="VoucherRow" key={`voucher-${voucher.code}`}>
              <Col xs={1} />
              <Col className="CartItemQuantity" xs={1}>
                1x
              </Col>
              <Col className="CartItemName VoucherName" xs={6}>
                {voucher.name}
                {' '}
                <span className="VoucherCode">
                  (
                  <VoucherIcon />
                  {' '}
                  {voucher.code}
                  )
                </span>
              </Col>
              <Col className="CartItemPrice" xs={4}>
                <MoneyString amount={voucher.amount.amount} currency={voucher.amount.currency} />
              </Col>
            </Row>
          ))}
          {amountNoDiscount > 0 && (
            <Row className="VoucherRow">
              <Col xs={1} />
              <Col className="CartItemQuantity" xs={1}>
                {amountNoDiscount}
                x
              </Col>
              <Col className="CartItemName VoucherName" xs={6}>
                <div className="CartItemNameTicket">{name}</div>
                {varietyName !== null
                  ? <div className="CartItemNameVariety">{varietyName}</div> : ''}
              </Col>
              <Col className="CartItemPrice" xs={4}>
                <MoneyString
                  amount={priceNoDiscount.amount}
                  currency={priceNoDiscount.currency}
                />
              </Col>
            </Row>
          )}
        </div>
      )}
    </div>
  );
};

export default CartItem;

CartItem.propTypes = {
  quantity: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  varietyName: PropTypes.string.isRequired,
  originalPrice: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
  }).isRequired,
  unitPrice: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
  }).isRequired,
  totalPrice: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
  }).isRequired,
  vouchers: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
  discounts: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    validFrom: PropTypes.string.isRequired,
    validTill: PropTypes.string.isRequired,
    amount: PropTypes.shape({
      currency: PropTypes.string.isRequired,
      amount: PropTypes.string.isRequired,
    }).isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
  })),
};

CartItem.defaultProps = {
  vouchers: [],
  discounts: [],
};
