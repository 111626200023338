import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import DocumentTitle from 'components/DocumentTitle';
import './Login.scss';
import Header from 'components/Header';
import Footer from 'components/Footer';
import LoginForm from 'components/Form/LoginForm';
import ToastContent from 'components/ToastContent';
import { getRelativeUrl } from '../../util';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emailSent: false,
    };
  }

  handleEmailSent = () => {
    this.setState({
      emailSent: true,
    });
  };

  render() {
    const { t, match, i18n } = this.props;
    const { emailSent } = this.state;

    const urlParams = queryString.parse(window.location.search);

    if (typeof urlParams.e !== 'undefined' && emailSent === false) {
      if (urlParams.e === 'session_expired') {
        toast.info(<ToastContent type="info" message={t('account.session_expired')} />);
      } else if (urlParams.e === 'key_expired') {
        toast.info(<ToastContent type="info" message={t('account.key_expired')} />);
      } else if (urlParams.e === 'key_invalid') {
        toast.error(<ToastContent type="error" message={t('account.key_invalid')} />);
      }
    }

    const redirectUrl = typeof urlParams.path !== 'undefined' ? `${window.location.origin}/${getRelativeUrl(urlParams.path)}` : `${window.location.origin}/${i18n.language}`;

    return (
      <div className="Login">
        <DocumentTitle title="Soul Survivor - Login" />

        <Header route={match} />

        <div className="Main">

          <Container>
            <Row>
              <Col md={{ span: 6, offset: 3 }} className="CheckOutColumn">
                <div className="LoginBlock">
                  {emailSent ? (
                    <div>
                      <h2>{t('login.sent.title')}</h2>
                      <div className="BlockExplanation">
                        {t('login.sent.subtitle')}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <h2>{t('login.page.title')}</h2>
                      <div className="BlockExplanation">
                        {t('login.page.subtitle')}
                      </div>

                      <LoginForm
                        onEmailSent={this.handleEmailSent}
                        redirectUrl={redirectUrl}
                      />
                    </div>
                  )}
                </div>
              </Col>
              <Col md={6} />
            </Row>

          </Container>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withTranslation()(Login);

Login.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
};

Login.defaultProps = {
  match: null,
};
