import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import EventHeader from 'components/EventHeader';
import DocumentTitle from 'components/DocumentTitle';
import './Volunteer.scss';
import VolunteerForm from 'components/Form/VolunteerForm';
import Header from 'components/Header';
import Footer from 'components/Footer';
import FullScreenLoader from 'components/Loader/FullScreenLoader';
import ToastContent from 'components/ToastContent';
import { logError } from 'services/error-handling/ErrorHandler';
import { handleInternalApiCall, METHOD_GET } from 'services/api-requests/ApiCallHandler';

class Volunteer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    this.setState({ isLoading: true });

    this.getEvent(match.params.eventSlug);
  }

  getEvent = (eventSlug) => {
    const { t } = this.props;

    handleInternalApiCall(`/shop/event/${eventSlug}/info`, METHOD_GET, false, null, (response) => {
      this.setState({
        event: response.data,
        isLoading: false,
      });
    }, (error) => {
      logError(error);
      toast.error(<ToastContent type="error" message={t('error.event')} />);
      this.setState({ isLoading: false });
    });
  };

  render() {
    const { t, match } = this.props;
    const { event, isLoading } = this.state;

    if (isLoading === true) return <FullScreenLoader route={match} />;

    return (
      <div className="Volunteer">
        <DocumentTitle
          title={`Soul Survivor ${event.name} | ${t('volunteer.page_title')}`}
        />

        <Header route={match} />

        <div className="Main">
          <EventHeader
            name={event.name}
            description={event.description}
            date={event.date}
            fromDate={event.startDateTime}
            tillDate={event.endDateTime}
            venue={event.location}
            tickets={event.tickets}
            small
            websiteUrl={event.websiteUrl}
            mapsUrl={event.mapsUrl}
          />

          <Container className="VolunteerContainer">
            <Row>
              <Col lg={7} className="VolunteerColumn CheckOutColumn">
                <div className="DetailsBlock Block">
                  <h2>{t('volunteer.title')}</h2>

                  <VolunteerForm
                    eventSlug={event.slug}
                    eventToken={event.token}
                  />
                </div>
              </Col>
              <Col lg={5} className="CheckOutColumn CheckOutInfo">
                <p>
                  <strong>
                    {t('order.event.overview.sidebar.detailsheader')}
                  </strong>
                  <br />
                  {t('order.event.overview.sidebar.details_volunteer')}
                </p>

                <Button
                  href={t('termsAndPrivacy.privacyStatement.url')}
                  variant="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('order.event.overview.sidebar.privacy')}
                  {' '}
                  <ChevronRightIcon />
                </Button>
              </Col>
            </Row>
          </Container>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withTranslation()(Volunteer);

Volunteer.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      eventSlug: PropTypes.string,
    }).isRequired,
  }).isRequired,
};
