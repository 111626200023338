import React, { Component } from 'react';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import MinusIcon from '@material-ui/icons/Remove';
import PlusIcon from '@material-ui/icons/Add';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { withTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import MoneyString from 'components/MoneyString/index';
import ToastContent from 'components/ToastContent';
import { logError } from 'services/error-handling/ErrorHandler';
import { handleInternalApiCall, METHOD_POST } from 'services/api-requests/ApiCallHandler';
import LocalStorageService from 'services/storage/LocalStorageService';
import { trackAddToCart } from 'services/tracking/TrackingHandler';
import { classNames } from '../../util';

class EventTicketVariety extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: props.amount,
      open: props.openByDefault,
    };
  }

  componentDidUpdate(prevProps) {
    const { amount } = this.props;

    if (amount !== prevProps.amount) {
      this.setState({ // eslint-disable-line react/no-did-update-set-state
        amount,
      });
    }
  }

  handleToggle = () => {
    const { open } = this.state;
    this.setState({
      open: !open,
    });
  };

  changeAmount = (amount) => {
    const {
      token, eventToken, trigger, t,
    } = this.props;

    if (amount <= 99) {
      this.setState({
        amount,
      });

      let task = 'edit';
      if (amount === 0) task = 'remove';

      handleInternalApiCall(`/shop/cart/${LocalStorageService.getCartUuid()}/event/${eventToken}/${task}/${token}`,
        METHOD_POST,
        false,
        {
          quantity: amount,
        },
        () => {
          trigger();

          trackAddToCart(token, amount);
        },
        (error) => {
          logError(error);
          toast.error(<ToastContent type="error" message={t('error.cart.edit')} />);
        });
    }
  };

  handleChange = (event) => {
    let amount = event.target.value;
    if (amount !== '') amount = parseInt(amount, 10);

    if (!Number.isNaN(amount)) {
      this.changeAmount(amount);
    }
  };

  handleMinus = () => {
    const { amount } = this.state;

    let newAmount = amount - 1;
    if (newAmount < 0) newAmount = 0;

    this.changeAmount(newAmount);
  };

  handlePlus = () => {
    const { amount } = this.state;
    const newAmount = amount + 1;
    this.changeAmount(newAmount);
  };

  render() {
    const {
      token, name, description, priceIncludingVat, discountedPriceIncludingVat, discounts, t, i18n,
    } = this.props;
    const { open, amount } = this.state;

    let price = priceIncludingVat;
    let discountTypes = [];
    let firstDiscount = null;

    if (discountedPriceIncludingVat) {
      price = discountedPriceIncludingVat;

      discountTypes = discounts.filter((discount) => (discount.active && (discount.type === 'EARLY_BIRD_DISCOUNT' || discount.type === 'EMPLOYEE_DISCOUNT')));

      [firstDiscount] = discountTypes;
    }

    return (
      <div className={classNames('EventTicketVariety', open && 'Opened')} id={token}>
        <div
          className="EventTicketVarietyHeader"
          onClick={this.handleToggle}
          onKeyDown={this.handleToggle}
          tabIndex={0}
          role="button"
        >
          <div className="EventTicketVarietyName">
            <h3>{name}</h3>

            {discountedPriceIncludingVat && typeof firstDiscount !== 'undefined' ? (
              <div
                className="DiscountBadge"
              >
                {t(`cart.discountTypes.${firstDiscount.type.toLowerCase()}`)}
              </div>
            ) : ''}
          </div>

          <div
            className={open ? 'EventTicketVarietyDropDown Show' : 'EventTicketVarietyDropDown Hidden'}
          >
            <ArrowDropDownCircleIcon className="EventTicketVarietyShowHide" />
          </div>

          <div className="EventTicketVarietyHeaderPrice">
            <MoneyString amount={price.amount} currency={price.currency} />
          </div>
        </div>
        {open
          ? (
            <div className="EventTicketVarietyBody">
              <div className="EventTicketVarietyDescription">
                {description && description.split('\n').map((item, key) => (// eslint-disable-next-line react/no-array-index-key
                  <span key={key}>
                    {item}
                    <br />
                  </span>
                ))}
              </div>

              <Row className="EventTicketVarietyFooter">
                <Col className="EventTicketVarietyPrice" md={6}>
                  <span className="EventTicketVarietyPriceCurrent">
                    <MoneyString amount={price.amount} currency={price.currency} />
                  </span>
                  {discountedPriceIncludingVat && (
                    <span className="EventTicketVarietyPriceNext">
                      <MoneyString
                        amount={priceIncludingVat.amount}
                        currency={priceIncludingVat.currency}
                      />
                    </span>
                  )}
                  {discountedPriceIncludingVat && firstDiscount !== null
                  && (
                    <div className="EventTicketVarietyPriceTill">
                      {t('event.discount_till')}
                      {' '}
                      <Moment
                        date={firstDiscount.validTill}
                        locale={i18n.language}
                        format="D MMM YYYY"
                      />
                    </div>
                  )}
                </Col>
                <Col className="EventTicketVarietyAmount" md={6}>
                  <Button
                    className="TicketButton TicketMinus"
                    disabled={amount === 0}
                    onClick={this.handleMinus}
                  >
                    <MinusIcon />
                  </Button>
                  <Form.Control
                    className="TicketAmount"
                    type="text"
                    value={amount}
                    onChange={this.handleChange}
                  />
                  <Button
                    className="TicketButton TicketPlus"
                    disabled={amount >= 99}
                    onClick={this.handlePlus}
                  >
                    <PlusIcon />
                  </Button>
                </Col>
              </Row>
            </div>
          ) : ''}
      </div>
    );
  }
}

export default withTranslation()(EventTicketVariety);

EventTicketVariety.propTypes = {
  amount: PropTypes.number.isRequired,
  openByDefault: PropTypes.bool.isRequired,
  eventToken: PropTypes.string.isRequired,
  trigger: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  priceIncludingVat: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
  }).isRequired,
  discountedPriceIncludingVat: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
  }),
  discounts: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    validFrom: PropTypes.string.isRequired,
    validTill: PropTypes.string.isRequired,
    amount: PropTypes.shape({
      currency: PropTypes.string.isRequired,
      amount: PropTypes.string.isRequired,
    }).isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
  })),
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
};

EventTicketVariety.defaultProps = {
  discountedPriceIncludingVat: null,
  discounts: [],
};
