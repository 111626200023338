import { ValidatorComponent } from 'react-form-validator-core';
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/Input/Checkbox';

export default class CheckboxGroup extends ValidatorComponent {
  createColumns = () => {
    const { checkboxes, columns, label } = this.props;

    const returnColumns = [];
    const amountPerColumn = Math.ceil(checkboxes.length / columns);
    const columnSize = Math.ceil(12 / columns);

    for (let columnNumber = 0; columnNumber < columns; columnNumber += 1) {
      const start = columnNumber * amountPerColumn;
      const checkboxesInColumn = checkboxes.slice(start, start + amountPerColumn);

      const columnContent = checkboxesInColumn.map(
        (checkbox, index) => (
          <Checkbox // eslint-disable-next-line react/no-array-index-key
            key={`${label}-${columnNumber}-${index}`}
            label={checkbox.label}
            name={checkbox.name}
            onChange={checkbox.onChange}
            onBlur={checkbox.onBlur}
            value={checkbox.value}
          />
        ),
      );

      returnColumns.push(
        <Col
          key={`${label}-${columnNumber}`}
          md={columnSize}
        >
          {columnContent}
        </Col>,
      );
    }

    return returnColumns;
  };

  render() {
    const { serverError } = this.props;

    return (
      <div className="CheckboxGroupWrapper">
        <Row className="CheckboxGroupRow">
          {this.createColumns()}
        </Row>

        {serverError && serverError.map(
          (errorText, index) => (// eslint-disable-next-line react/no-array-index-key
            <Form.Text key={index} className="InputError">
              {errorText}
            </Form.Text>
          ),
        )}
      </div>
    );
  }
}

CheckboxGroup.propTypes = {
  label: PropTypes.string.isRequired,
  columns: PropTypes.number,
  checkboxes: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]).isRequired,
  })),
};

CheckboxGroup.defaultProps = {
  columns: 1,
};
