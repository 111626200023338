/* eslint-disable class-methods-use-this, no-unused-vars */
import PaymentMethod from 'services/payment/methods/PaymentMethod';

export default class ApplePayPaymentMethod extends PaymentMethod {
  constructor() {
    super();
    this.session = null;
  }

  handlePayment(orderSlug, onSuccess, onError) {
    this.session.begin();

    super.handlePayment(orderSlug, onSuccess, onError);
  }

  isValid() {
    return true;
  }

  handleSubmit(amount, callback) {
    /* global ApplePaySession */
    this.session = new ApplePaySession(3, {
      countryCode: 'NL',
      currencyCode: amount.currency,
      supportedNetworks: ['amex', 'maestro', 'masterCard', 'visa', 'vPay'],
      merchantCapabilities: ['supports3DS'],
      total: { label: 'Stichting Soul Survivor Activities', amount: amount.amount },
    });

    callback();
  }
}
