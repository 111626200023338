import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { withTranslation } from 'react-i18next';
import VoucherIcon from '@material-ui/icons/CardGiftcard';
import SubArrowIcon from '@material-ui/icons/SubdirectoryArrowRight';
import PropTypes from 'prop-types';
import MoneyString from 'components/MoneyString/index';
import CartRemoveMessage from 'components/Cart/CartRemoveMessage';

class VoucherRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRemoveConfirmation: false,
    };
  }

  handleRemove = () => {
    this.setState({
      showRemoveConfirmation: true,
    });
  };

  handleRemoveCancel = () => {
    this.setState({
      showRemoveConfirmation: false,
    });
  };

  handleRemoveConfirm = () => {
    const { code, remove } = this.props;
    this.setState({
      showRemoveConfirmation: false,
    });

    remove(code, true);
  };

  render() {
    const { code, name, amount } = this.props;
    const { showRemoveConfirmation } = this.state;

    return (
      <div className="VoucherRow" key={`voucher-${code}`}>
        <SubArrowIcon />
        1x
        {' '}
        {name}
        {' '}
        à
        <span className="VoucherPrice">
          <MoneyString amount={amount.amount} currency={amount.currency} />
        </span>
        {' '}
        <span className="VoucherCode">
          (
          <VoucherIcon />
          {' '}
          {code}
          )
        </span>

        {showRemoveConfirmation
        && (
        <CartRemoveMessage
          cancel={this.handleRemoveCancel}
          confirm={this.handleRemoveConfirm}
          voucher
        />
        )}
        <Button
          variant="link"
          className="RemoveButton"
          onClick={this.handleRemove}
        >
          <DeleteIcon className="RemoveButtonIcon" />
        </Button>
      </div>
    );
  }
}

export default withTranslation()(VoucherRow);

VoucherRow.propTypes = {
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired,
  amount: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
  }).isRequired,
};
