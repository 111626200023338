import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import thunk from 'redux-thunk';
import App from 'components/App';
import reducers from './reducers/rootReducer';
import { getEnvironment } from './util';

Sentry.init({
  dsn: 'https://0a669b04bca7461baf56c069990a2c52@sentry.io/1812703',
  release: window.SENTRY_VERSION,
  environment: getEnvironment(),
});

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
const store = createStoreWithMiddleware(reducers);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);
