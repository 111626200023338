import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { generatePath } from 'react-router-dom';
import LocalStorageService from '../storage/LocalStorageService';
import { logError } from '../error-handling/ErrorHandler';
import i18n from '../../i18n';

const instance = axios.create();

// Function that will be called to refresh authorization
const refreshAuthLogic = () => axios.post('/api/login/refresh', {
  refreshToken: LocalStorageService.getRefreshToken(),
}).then((tokenRefreshResponse) => {
  LocalStorageService.setToken({
    access_token: tokenRefreshResponse.data.token,
    refresh_token: tokenRefreshResponse.data.refreshToken,
  });
  return Promise.resolve();
}).catch((error) => {
  logError(error);
  LocalStorageService.clearToken();
  window.location.href = `/${i18n.language}${generatePath(`${i18n.t('routes:ticket.account_login')}?e=session_expired`)}`;
  return false;
});

// Use interceptor to inject the token to requests
instance.interceptors.request.use((request) => {
  if (LocalStorageService.getAccessToken() !== null) {
    request.headers.Authorization = `Bearer ${LocalStorageService.getAccessToken()}`;
  }
  return request;
});

createAuthRefreshInterceptor(instance, refreshAuthLogic);

export default instance;
