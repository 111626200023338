import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { withTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-form-validator-core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Input from 'components/Input/index';
import ToastContent from 'components/ToastContent';
import { logError } from 'services/error-handling/ErrorHandler';
import { handleInternalApiCall, METHOD_POST } from 'services/api-requests/ApiCallHandler';
import LocalStorageService from 'services/storage/LocalStorageService';
import { removeQuery } from 'services/routing/query-params';

class VoucherForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      voucherCode: '',
    };
  }

  componentDidMount() {
    const urlParams = queryString.parse(window.location.search);

    if (typeof urlParams.voucher !== 'undefined') {
      this.addVoucher(urlParams.voucher);
      removeQuery('voucher');
    }
  }

  handleChange = (event) => {
    this.setState({
      voucherCode: event.target.value,
    });
  };

  addVoucher = (voucherCode) => {
    const { t, eventToken, loadCart } = this.props;

    handleInternalApiCall(`/shop/cart/${LocalStorageService.getCartUuid()}/event/${eventToken}/voucher/add/${voucherCode}`,
      METHOD_POST,
      false,
      null,
      () => {
        loadCart();
      },
      (error) => {
        if (error.response && typeof error.response.data.error !== 'undefined') {
          toast.error(<ToastContent type="error" code={error.response.data.error} />);
        } else {
          logError(error);
          toast.error(<ToastContent type="error" message={t('error.voucher.post')} />, {
            position: 'top-center',
            autoClose: false,
          });
        }
      });
  };

  handleSubmit = (event) => {
    const { voucherCode } = this.state;
    event.preventDefault();

    if (voucherCode !== '') {
      this.addVoucher(voucherCode);
    }

    return false;
  };

  render() {
    const { t, toggleVoucherClick } = this.props;
    const { voucherCode } = this.state;

    return (
      <ValidatorForm
        ref={(node) => { this.form = node; }}
        onSubmit={this.handleSubmit}
        method="post"
      >
        <Row className="CartVoucherInput">
          <Col xs={9}>
            <Input
              autoFocus
              ref={(input) => {
                this.voucherInput = input;
              }}
              placeholder={t('cart.voucher')}
              name="voucher"
              onChange={this.handleChange}
              value={voucherCode}
              validators={['required']}
              errorMessages={[t('form.validation.required')]}
            />
          </Col>
          <Col xs={2}>
            <Button
              className="AddVoucherLink"
              variant="success"
              type="submit"
              disabled={voucherCode === ''}
            >
              <AddIcon />
            </Button>
          </Col>
          <Col xs={1}>
            <Button
              className="CartVoucherInputClose"
              variant="link"
              onClick={toggleVoucherClick}
            >
              <CloseIcon className="CartVoucherInputCloseIcon" />
            </Button>
          </Col>
        </Row>
      </ValidatorForm>
    );
  }
}

export default withTranslation()(VoucherForm);

VoucherForm.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  eventToken: PropTypes.string.isRequired,
  loadCart: PropTypes.func.isRequired,
  toggleVoucherClick: PropTypes.func.isRequired,
};
