import React from 'react';
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';

import './CartRemoveMessage.scss';
import PropTypes from 'prop-types';

const CartRemoveMessage = ({
  quantity, cancel, confirm, voucher,
}) => {
  const { t } = useTranslation();

  return (
    <Popover className="CartRemoveMessage" id="popover-basic" placement="auto" outOfBoundaries>
      <h3>{t('cart.delete.title')}</h3>
      <div className="CartRemoveMessageText">
        {voucher && t('cart.delete.content.voucher')}
        {!voucher && (quantity === 1 ? t('cart.delete.content.single') : t('cart.delete.content.plural'))}
      </div>
      <div className="ConfirmButtons">
        <Button variant="light" onClick={cancel}>
          {t('cart.delete.button.cancel.label')}
        </Button>
        <Button variant="success" onClick={confirm}>
          {t('cart.delete.button.remove.label')}
        </Button>
      </div>
    </Popover>
  );
};

export default CartRemoveMessage;

CartRemoveMessage.propTypes = {
  quantity: PropTypes.number.isRequired,
  cancel: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  voucher: PropTypes.bool.isRequired,
};
