import React from 'react';
import PropTypes from 'prop-types';
import './RadioGroup.scss';

const RadioGroup = ({
  value, options, onChange, name, variant,
}) => (
  <div className={`RadioGroup${variant === 'horizontal' ? ' Horizontal' : ' Vertical'}`}>
    {options.map((option) => (
      <RadioOption
        key={option.value}
        label={option.label}
        checked={value === option.value}
        value={option.value}
        onChange={() => onChange({ target: { name, value: option.value } })}
        name={name}
      />
    ))}
  </div>
);

const RadioOption = ({
  label, checked, onChange, name, value,
}) => (
  <label className="RadioOption">
    <input
      type="radio"
      onChange={onChange}
      value={value}
      checked={checked}
      name={name}
    />
    <div className="icon" />
    <span>{label}</span>
  </label>
);

export default RadioGroup;

RadioGroup.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]).isRequired,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
};

RadioOption.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
};
