import React, { Component } from 'react';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DateRangeIcon from '@material-ui/icons/DateRange';
import LocationIcon from '@material-ui/icons/LocationOnOutlined';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Moment from 'react-moment';
import { withTranslation } from 'react-i18next';
import VisibilitySensor from 'react-visibility-sensor';

import './EventHeader.scss';
import { Sticky } from 'react-sticky';
import PropTypes from 'prop-types';
import { classNames } from '../../util';

class EventHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headerVisible: true,
    };
  }

  handleVisibility = (isVisible) => {
    this.setState({
      headerVisible: isVisible,
    });
  };

  render() {
    const {
      name, description, fromDate, tillDate, venue, mapsUrl,
      websiteUrl, tickets, children, small, t, i18n,
    } = this.props;
    const { headerVisible } = this.state;

    let eventHeaderSmallSize = 7;
    if (small) eventHeaderSmallSize = 12;

    return (
      <div className="EventHeaderWrapper">
        <VisibilitySensor onChange={this.handleVisibility} partialVisibility offset={{ top: 80 }}>
          <div className="EventHeader">
            <Container>
              <Row>
                <Col className="EventInformation" lg={7}>
                  <h1 className="EventName">{name}</h1>
                  <div className="EventInformationBar">
                    <div className="EventInformation EventDate">
                      <DateRangeIcon className="EventInformationIcon" />
                      <Moment date={fromDate} locale={i18n.language} format="D MMM YYYY" />
                      {fromDate !== tillDate && (
                        <span>
&nbsp;
                          {t('event.date_till')}
                          {' '}
                          <Moment
                            date={tillDate}
                            locale={i18n.language}
                            format="D MMM YYYY"
                          />
                        </span>
                      )}
                    </div>
                    {mapsUrl ? (
                      <div className="EventInformation EventVenue EventVenueLink">
                        <a href={mapsUrl} target="_blank" rel="noopener noreferrer">
                          <LocationIcon className="EventInformationIcon" />
                          {venue}
                        </a>
                      </div>
                    ) : (
                      <div className="EventInformation EventVenue">
                        <LocationIcon className="EventInformationIcon" />
                        {venue}
                      </div>
                    )}
                    {websiteUrl && (
                      <div className="EventInformation EventWebsite">
                        <a href={websiteUrl} target="_blank" rel="noopener noreferrer">
                          <OpenInNewIcon className="EventInformationIcon" />
                          {t('event.more_information')}
                        </a>
                      </div>
                    )}
                  </div>
                  {!small && (
                    <div className="EventAdditionalInformation">
                      <div className="EventDescription">
                        {description}
                      </div>

                      <Nav className="EventTicketTypes" as="ul">
                        {tickets.length > 0 && tickets.map((ticket) => (
                          <Nav.Item as="li" key={ticket.token}>
                            <Nav.Link
                              id={`#ticket-link-${ticket.token}`}
                              href={`#${ticket.token}`}
                            >
                              {ticket.name}
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                    </div>
                  )}
                </Col>

                <Col lg={5} className="EventCart">
                  {children
                  && (
                    <Sticky topOffset={-80} bottomOffset={140}>
                      {({
                        style,
                        isSticky,
                      }) => (
                        <div
                          style={style}
                          className={classNames('StickyCart', isSticky && 'Active')}
                        >
                          {children}
                        </div>
                      )}
                    </Sticky>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        </VisibilitySensor>
        {!headerVisible && (
          <div className="EventHeader EventHeaderSmallTop">
            <Container>
              <Row>
                <Col className="EventInformation" lg={eventHeaderSmallSize}>
                  <Row>
                    <Col sm={5}>
                      <h1 className="EventName">{name}</h1>
                    </Col>
                    <Col className="EventInformationBar" sm={7}>
                      <div className="EventInformation EventDate">
                        <DateRangeIcon className="EventInformationIcon" />
                        {fromDate === tillDate
                          ? <Moment date={fromDate} locale={i18n.language} format="D MMM YYYY" />
                          : (
                            <span>
                              <Moment
                                date={fromDate}
                                locale={i18n.language}
                                format="D MMM"
                              />
                              {' '}
                              {t('event.date_till')}
                              {' '}
                              <Moment
                                date={tillDate}
                                locale={i18n.language}
                                format="D MMM YYYY"
                              />
                            </span>
                          )}
                      </div>
                      {mapsUrl ? (
                        <div className="EventInformation EventVenue EventVenueLink">
                          <a href={mapsUrl} target="_blank" rel="noopener noreferrer">
                            <LocationIcon className="EventInformationIcon" />
                            {venue}
                          </a>
                        </div>
                      ) : (
                        <div className="EventInformation EventVenue">
                          <LocationIcon className="EventInformationIcon" />
                          {venue}
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(EventHeader);

EventHeader.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  fromDate: PropTypes.string.isRequired,
  tillDate: PropTypes.string.isRequired,
  venue: PropTypes.string.isRequired,
  mapsUrl: PropTypes.string,
  websiteUrl: PropTypes.string,
  tickets: PropTypes.arrayOf(PropTypes.shape({
    token: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    dietPreference: PropTypes.bool.isRequired,
    minQuantity: PropTypes.number,
    maxQuantity: PropTypes.number,
    varieties: PropTypes.arrayOf(PropTypes.shape({
      token: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      capacity: PropTypes.number,
      priceIncludingVat: PropTypes.shape({
        currency: PropTypes.string.isRequired,
        amount: PropTypes.string.isRequired,
      }).isRequired,
      discountedPriceIncludingVat: PropTypes.shape({
        currency: PropTypes.string.isRequired,
        amount: PropTypes.string.isRequired,
      }),
      validFrom: PropTypes.string.isRequired,
      validTill: PropTypes.string.isRequired,
      openByDefault: PropTypes.bool.isRequired,
      discounts: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string.isRequired,
        active: PropTypes.bool.isRequired,
        validFrom: PropTypes.string.isRequired,
        validTill: PropTypes.string.isRequired,
        amount: PropTypes.shape({
          currency: PropTypes.string.isRequired,
          amount: PropTypes.string.isRequired,
        }).isRequired,
        min: PropTypes.number,
        max: PropTypes.number,
      })),
    })).isRequired,
  })),
  children: PropTypes.node,
  small: PropTypes.bool.isRequired,
};

EventHeader.defaultProps = {
  mapsUrl: null,
  websiteUrl: null,
  tickets: [],
  children: null,
};
