import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { StickyContainer } from 'react-sticky';
import { generatePath, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import EventHeader from 'components/EventHeader';
import EventTickets from 'components/EventTickets';
import Cart from 'components/Cart';
import CartMobile from 'components/Cart/CartMobile';
import DocumentTitle from 'components/DocumentTitle';

import './SingleEvent.scss';
import Header from 'components/Header';
import Footer from 'components/Footer';
import FullScreenLoader from 'components/Loader/FullScreenLoader';
import ToastContent from 'components/ToastContent';
import { logError } from 'services/error-handling/ErrorHandler';
import { handleInternalApiCall, METHOD_GET, METHOD_POST } from 'services/api-requests/ApiCallHandler';
import Alert from 'components/Alert';
import { getMarketingUrlParams } from 'services/tracking/TrackingHandler';
import LocalStorageService from 'services/storage/LocalStorageService';

class SingleEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: null,
      tickets: null,
      cartAmount: {},
      shouldUpdateCart: false,
      isLoading: true,
      employee: false,
    };
  }

  componentDidMount() {
    const { t, match } = this.props;

    handleInternalApiCall(`/shop/event/${match.params.eventSlug}/tickets`,
      METHOD_GET,
      false,
      null,
      (response) => {
        const { event } = response.data;

        this.setState({
          event,
          tickets: response.data.tickets,
          isLoading: false,
        }, () => {
          this.handleTracking();
        });
      },
      (error) => {
        logError(error);
        toast.error(<ToastContent type="error" message={t('error.event')} />);
        this.setState({ isLoading: false });
      });
  }

  handleTracking = () => {
    const { event } = this.state;
    const marketingParams = getMarketingUrlParams();

    if (marketingParams) {
      handleInternalApiCall(`/shop/cart/${LocalStorageService.getCartUuid()}/event/${event.token}/campaign`,
        METHOD_POST,
        false,
        {
          source: marketingParams.source,
          campaign: marketingParams.campaign,
          medium: marketingParams.medium,
          content: marketingParams.content,
          term: marketingParams.term,
        });
    }
  };

  triggerUpdate = () => {
    this.setState({
      shouldUpdateCart: true,
    });
  };

  updateCart = (cartItems, employee) => {
    const amount = {};

    for (let i = 0; i < cartItems.length; i += 1) {
      amount[cartItems[i].token] = cartItems[i].quantity;
    }

    this.setState({
      cartAmount: amount,
      shouldUpdateCart: false,
      employee,
    });
  };

  render() {
    const { match, i18n, t } = this.props;
    const {
      event, employee, shouldUpdateCart, cartAmount, tickets, isLoading,
    } = this.state;

    if (isLoading) return <FullScreenLoader route={match} />;

    if (employee === true) {
      return (
        <Redirect
          to={`/${i18n.language}${generatePath(t('routes:ticket.checkout'),
            { eventSlug: event.slug })}`}
        />
      );
    }

    return (
      <div className="Event">
        <Header route={match} />

        <DocumentTitle title={`Soul Survivor ${event.name} | Tickets`} />

        <div className="Main">
          <StickyContainer>
            <EventHeader
              name={event.name}
              description={event.description}
              date={event.date}
              fromDate={event.startDateTime}
              tillDate={event.endDateTime}
              venue={event.location}
              tickets={tickets}
              small={false}
              websiteUrl={event.websiteUrl}
              mapsUrl={event.mapsUrl}
            >
              <Cart
                eventSlug={event.slug}
                eventToken={event.token}
                image={event.heroImage}
                onUpdate={this.updateCart}
                update={shouldUpdateCart}
              />
            </EventHeader>

            <Container>
              <Row>
                <Col className="EventTicketsWrapper" lg={7}>
                  <Alert variant="info" style={{ marginTop: 15 }}>
                    <strong>
                      {t('event.alert_youthleader.heading')}
                      {' '}
                    </strong>
                    {t('event.alert_youthleader.content')}
                  </Alert>

                  <EventTickets
                    eventSlug={event.slug}
                    eventToken={event.token}
                    tickets={tickets}
                    cartAmount={cartAmount}
                    trigger={this.triggerUpdate}
                  />
                </Col>
              </Row>
            </Container>

            <CartMobile
              eventSlug={event.slug}
              eventToken={event.token}
              image={event.heroImage}
              onUpdate={this.updateCart}
              update={shouldUpdateCart}
            />
          </StickyContainer>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withTranslation()(SingleEvent);

SingleEvent.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      eventSlug: PropTypes.string,
    }).isRequired,
  }).isRequired,
};
