import React, { Component } from 'react';

import './Cart.scss';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { withTranslation } from 'react-i18next';
import { LinkContainer } from 'react-router-bootstrap';
import { generatePath } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import CartItem from 'components/Cart/CartItem';
import ToastContent from 'components/ToastContent';
import { logError } from 'services/error-handling/ErrorHandler';
import { handleInternalApiCall, METHOD_GET } from 'services/api-requests/ApiCallHandler';
import LocalStorageService from 'services/storage/LocalStorageService';
import { trackInitiateCheckOut } from 'services/tracking/TrackingHandler';
import MoneyString from '../MoneyString';

class SmallCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isLoaded: false,
      products: [],
      totalPrice: { currency: '', amount: '' },
      voucher: null,
      tracked: false,
      employee: false,
    };
  }

  componentDidMount() {
    this.loadCart();
  }

  loadCart = () => {
    this.setState({ isLoading: true });
    const { tracked } = this.state;
    const {
      eventToken, track, onUpdate, t,
    } = this.props;

    handleInternalApiCall(`/shop/cart/${LocalStorageService.getCartUuid()}/event/${eventToken}`,
      METHOD_GET,
      false,
      null,
      (response) => {
        if (track && tracked === false && typeof window.fbq !== 'undefined') {
          trackInitiateCheckOut(response.data);
        }

        this.setState({
          isLoading: false,
          isLoaded: true,
          products: response.data.products,
          totalPrice: response.data.totalPrice,
          voucher: response.data.voucher,
          tracked: true,
          employee: response.data.employee,
        });

        onUpdate(response.data.products, response.data.employee, response.data.totalPrice);
      },
      (error) => {
        logError(error);
        toast.error(<ToastContent type="error" message={t('error.cart.get')} />);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const {
      t, i18n, eventName, eventSlug,
    } = this.props;
    const {
      isLoaded, isLoading, products, employee, totalPrice, voucher,
    } = this.state;

    return (
      <div className="SmallCart">
        <div className="CartBody">
          <h2>
            {t('cart.title')}
            {eventName && ` ${t('cart.for')} ${eventName}`}
          </h2>
          {isLoading && <Loader className="CartLoader" />}
          {products.length > 0
            ? (
              <div className={isLoading ? 'CartInner Loading' : 'CartInner'}>
                <div className="CartContents">
                  {products.map((item) => (
                    <CartItem
                      key={`cartitem-${item.token}`}
                      name={item.name}
                      varietyName={item.varietyName}
                      quantity={item.quantity}
                      originalPrice={item.originalPrice}
                      unitPrice={item.unitPrice}
                      totalPrice={item.totalPrice}
                      discounts={item.discounts}
                      vouchers={item.vouchers}
                    />
                  ))}
                  {voucher && (
                    <div className="CartVouchers">
                      {voucher.map((item) => (
                        <CartItem
                          key={`voucheritem-${item.id}`}
                          voucher
                          name={item.voucher_name}
                          voucher_discount={item.discount}
                        />
                      ))}
                    </div>
                  )}
                </div>
                <Row className="CartTotal">
                  <Col className="CartTotalText" xs={8}>
                    <div className="CartTotalTextTotal">
                      {t('cart.total')}
                    </div>
                    <div className="CartTotalTextDescription">
                      {t('cart.includingVAT')}
                    </div>
                  </Col>
                  <Col className="CartTotalAmount" xs={4}>
                    <MoneyString
                      amount={totalPrice.amount}
                      currency={totalPrice.currency}
                    />
                  </Col>
                </Row>
              </div>
            ) : (
              <div className="CartContents">
                {isLoaded && (
                  <div className="CartEmpty">
                    {t('cart.cart_is_empty')}
                  </div>
                )}
              </div>
            )}

          {employee === false && (
            <div className="SmallCartButtons">
              <LinkContainer
                to={`/${i18n.language}${generatePath(t('routes:ticket.cart'),
                  { eventSlug })}`}
              >
                <Button className="BackLink" variant="link">
                  <ChevronLeftIcon />
                  {' '}
                  {t('button.edit_cart')}
                </Button>
              </LinkContainer>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(SmallCart);

SmallCart.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  eventSlug: PropTypes.string.isRequired,
  eventToken: PropTypes.string.isRequired,
  eventName: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  track: PropTypes.bool.isRequired,
};

SmallCart.defaultProps = {
  eventName: null,
};
