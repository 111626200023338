import React, { Component } from 'react';

import './Cart.scss';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { withTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import VerifiedIcon from '@material-ui/icons/VerifiedUser';
import VoucherIcon from '@material-ui/icons/CardGiftcard';
import { LinkContainer } from 'react-router-bootstrap';
import { generatePath } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { logError } from 'services/error-handling/ErrorHandler';
import { handleInternalApiCall, METHOD_GET } from 'services/api-requests/ApiCallHandler';
import LocalStorageService from 'services/storage/LocalStorageService';
import VoucherForm from 'components/Form/VoucherForm';
import Loader from 'components/Loader';
import CartItem from 'components/Cart/CartItem';
import ToastContent from 'components/ToastContent';

class CartMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isLoaded: false,
      expanded: false,
      products: [],
      count: 0,
      showVoucherInput: false,
    };
  }

  componentDidMount() {
    this.loadCart();
  }

  componentDidUpdate(prevProps) {
    const { update } = this.props;
    if (update !== prevProps.update && update) {
      this.loadCart();
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('CartOpened');
  }

  loadCart = () => {
    const { eventToken, onUpdate, t } = this.props;
    this.setState({ isLoading: true });

    handleInternalApiCall(`/shop/cart/${LocalStorageService.getCartUuid()}/event/${eventToken}`,
      METHOD_GET,
      false,
      null,
      (response) => {
        let count = 0;
        for (let i = 0; i < response.data.products.length; i += 1) {
          count += response.data.products[i].quantity;
        }

        this.setState({
          isLoading: false,
          isLoaded: true,
          products: response.data.products,
          count,
          showVoucherInput: false,
        });

        onUpdate(response.data.products);
      },
      (error) => {
        logError(error);
        toast.error(<ToastContent type="error" message={t('error.cart.get')} />);
        this.setState({ isLoading: false });
      });
  };

  toggleVoucherClick = () => {
    const { showVoucherInput } = this.state;
    this.setState({
      showVoucherInput: !showVoucherInput,
    });
  };

  handleToggle = () => {
    const { count, expanded } = this.state;
    if (count > 0) {
      const newExpanded = !expanded;

      this.setState({
        expanded: newExpanded,
      });

      if (newExpanded) {
        document.body.classList.add('CartOpened');
      } else {
        document.body.classList.remove('CartOpened');
      }
    }
  };

  render() {
    const {
      image, eventToken, eventSlug, t, i18n,
    } = this.props;
    const {
      expanded, count, isLoading, products, showVoucherInput, total, isLoaded,
    } = this.state;

    if (expanded && count > 0) {
      return (
        <div className="MobileCart MobileCartFull">
          <div className="CartHeader">
            <Button className="CloseButton" variant="light" onClick={this.handleToggle}>
              <CloseIcon />
            </Button>
            <img src={image} alt="" />
          </div>
          <div className="CartBody">
            <h2>{t('cart.title')}</h2>
            {isLoading && <Loader className="CartLoader" />}
            <div className={isLoading ? 'CartInner Loading' : 'CartInner'}>
              <div className="CartContents">
                {products.map((item) => (
                  <CartItem
                    key={`cartitem-${item.token}`}
                    name={item.name}
                    varietyName={item.varietyName}
                    quantity={item.quantity}
                    originalPrice={item.originalPrice}
                    unitPrice={item.unitPrice}
                    totalPrice={item.totalPrice}
                    discounts={item.discounts}
                    vouchers={item.vouchers}
                  />
                ))}
              </div>
              <Row className="CartTotal">
                <Col className="CartTotalText" xs={8}>
                  <div className="CartTotalTextTotal">
                    {t('cart.total')}
                  </div>
                  <div className="CartTotalTextDescription">
                    {t('cart.includingVAT')}
                  </div>
                </Col>
                <Col className="CartTotalAmount" xs={4}>
                  {total}
                </Col>
              </Row>

              {(!showVoucherInput) && (
                <div className="CartVoucherAdd">
                  <Button
                    className="AddVoucherLink"
                    variant="link"
                    onClick={this.toggleVoucherClick}
                  >
                    <VoucherIcon />
                    {' '}
                    {t('cart.addVoucher')}
                  </Button>
                </div>
              )}
              {showVoucherInput && (
                <VoucherForm
                  loadCart={this.loadCart}
                  eventToken={eventToken}
                  toggleVoucherClick={this.toggleVoucherClick}
                />
              )}
            </div>

            <div className={isLoading ? 'CartButtons Loading' : 'CartButtons'}>
              <LinkContainer
                to={`/${i18n.language}${generatePath(t('routes:ticket.cart'),
                  { eventSlug })}`}
              >
                <Button
                  variant="primary"
                  block
                  size="lg"
                  disabled={products.length === 0}
                >
                  {t('event.button.buy_tickets')}
                </Button>
              </LinkContainer>
            </div>

            <div className="SafePayment">
              <VerifiedIcon className="SafePaymentIcon" />
              {' '}
              {t('cart.safePayment')}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        className="MobileCart MobileCartBar"
        onClick={this.handleToggle}
        onKeyDown={this.handleToggle}
        tabIndex={0}
        role="button"
      >
        <Container className={isLoading && 'Loading'}>
          <div className="CartBody">
            <h2>{t('cart.title')}</h2>
            {isLoading && <Loader className="CartLoader" />}
            {count > 0
              ? (
                <Row className="CartTotal">
                  <Col className="CartTotalText" xs={8}>
                    <div className="CartTotalTextTotal">
                      {count}
                      {' '}
                      {count === 1 ? t('cart.ticket') : t('cart.tickets')}
                    </div>
                  </Col>
                  <Col className="CartTotalAmount" xs={4}>
                    {total}
                  </Col>
                </Row>
              ) : (
                <div className="CartContents">
                  {isLoaded && (
                    <div className="CartEmpty">
                      {t('cart.cart_is_empty')}
                    </div>
                  )}
                </div>
              )}
          </div>
        </Container>
      </div>
    );
  }
}

export default withTranslation()(CartMobile);

CartMobile.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  image: PropTypes.string.isRequired,
  eventSlug: PropTypes.string.isRequired,
  eventToken: PropTypes.string.isRequired,
  update: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
};
