import React from 'react';
import { ValidatorComponent } from 'react-form-validator-core';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import PropTypes from 'prop-types';

import './Input.scss';

class Dropdown extends ValidatorComponent {
  render() {
    const {
      onClick, options, placeholder, onChange, onBlur, value, name, isOptionDisabled, menuPosition,
    } = this.props;

    return (
      <Form.Group className="Select" onClick={onClick}>
        <Select
          options={options}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          name={name}
          isOptionDisabled={isOptionDisabled}
          ref={(r) => {
            this.input = r;
          }}
          menuPosition={menuPosition}
        />
        {this.errorText()}
      </Form.Group>
    );
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return (
      <Form.Text className="InputError">
        {this.getErrorMessage()}
      </Form.Text>
    );
  }
}

export default Dropdown;

Dropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]).isRequired,
  })).isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  errorMessages: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]).isRequired,
  }),
  name: PropTypes.string.isRequired,
  isOptionDisabled: PropTypes.bool,
  isValid: PropTypes.bool,
};

Dropdown.defaultProps = {
  placeholder: null,
  validators: [],
  errorMessages: [],
  onBlur: () => {},
  onClick: () => {},
  isOptionDisabled: false,
  isValid: null,
  validatorListener: () => {},
  value: null,
};
