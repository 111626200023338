/* eslint-disable class-methods-use-this, no-unused-vars */
import PaymentMethod from 'services/payment/methods/PaymentMethod';

export default class BancontactPaymentMethod extends PaymentMethod {
  handlePayment(orderSlug, onSuccess, onError) {
    const data = {
      paymentMethod: 'bancontact',
    };

    super.handlePayment(orderSlug, data, onSuccess, onError);
  }

  isValid() {
    return true;
  }
}
