import { createBrowserHistory } from 'history';
import queryString from 'query-string';

/**
 * @param {...String} queryNames
 */
export const removeQuery = (...queryNames) => {
  const browserHistory = createBrowserHistory();
  const location = { ...browserHistory.location };
  const urlParams = queryString.parse(location.search);
  queryNames.forEach((q) => delete urlParams[q]);

  const newUrl = `${location.pathname}?${queryString.stringify(urlParams)}`;
  browserHistory.push(newUrl);
};

export default removeQuery;
