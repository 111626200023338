import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from 'components/Header/index';
import Footer from 'components/Footer/index';
import Loader from './index';

import './Loader.scss';

export default () => {
  const { t } = useTranslation();

  return (
    <div className="PageLoading LoginLoader">
      <Header />

      <div className="Main">
        <Loader className="PageLoader HasText" />
        <div className="LoaderText">
          {t('loading.login')}
        </div>
      </div>

      <Footer />
    </div>
  );
};
