import React, { Component } from 'react';
import { Redirect, Route, Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { createBrowserHistory } from 'history';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { isValidElementType } from 'react-is';
import LoginLoader from 'components/Loader/LoginLoader';
import * as sessionActions from 'actions/sessionActions';
import { trackPageView } from 'services/tracking/TrackingHandler';
import i18n from '../../i18n';

const history = createBrowserHistory();

history.listen(() => {
  trackPageView(window.location.href);
});

class LocalizedRouter extends Component {
  componentDidMount() {
    trackPageView(window.location.href);
  }

  render() {
    const {
      children,
      RouterComponent,
      loggedIn,
      actions,
    } = this.props;

    return (
      <RouterComponent history={history}>
        <Route path="/:lang([a-z]{2})">
          {({ match, location }) => {
            /**
             * Get current language
             * Set default locale to en if base path is used without a language
             */
            const params = match ? match.params : {};
            const { lang = 'nl' } = params;

            /**
             * If language is not in route path, redirect to language root
             */
            const { pathname } = location;

            const urlParams = queryString.parse(location.search);

            if (typeof urlParams.key !== 'undefined') {
              if (!loggedIn) {
                actions.logInUserByToken(urlParams.key);
                return <LoginLoader />;
              }
              return <Redirect to={pathname} />;
            }

            if (!pathname.includes(`/${lang}/`)) {
              return <Redirect to={`/${lang}/`} />;
            }

            /**
             * Return Intl provider with default language set
             */
            return (
              <I18nextProvider i18n={i18n}>
                {children}
              </I18nextProvider>
            );
          }}
        </Route>
      </RouterComponent>
    );
  }
}

function mapStateToProps(state) {
  return { loggedIn: state.session };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(sessionActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocalizedRouter);

LocalizedRouter.propTypes = {
  children: PropTypes.node.isRequired,
  RouterComponent: (props, propName) => { // eslint-disable-next-line react/destructuring-assignment
    const prop = props[propName];
    if (prop && !isValidElementType(prop)) {
      return new Error(
        'Invalid prop \'component\' supplied to \'Route\': the prop is not a valid React component',
      );
    }
    return null;
  },
  loggedIn: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    logInUserByToken: PropTypes.func.isRequired,
  }).isRequired,
};

LocalizedRouter.defaultProps = {
  RouterComponent: Router,
};
