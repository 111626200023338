/* eslint-disable class-methods-use-this, no-unused-vars */
import PaymentMethod from 'services/payment/methods/PaymentMethod';

export default class IdealPaymentMethod extends PaymentMethod {
  constructor() {
    super();
    this.issuer = null;
  }

  handlePayment(orderSlug, onSuccess, onError) {
    const data = {
      paymentMethod: 'ideal',
      idealIssuer: this.issuer,
    };

    super.handlePayment(orderSlug, data, onSuccess, onError);
  }

  isValid() {
    return this.issuer !== null;
  }

  setDetails(details) {
    this.issuer = details;
  }

  getDetails() {
    return this.issuer;
  }
}
