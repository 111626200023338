import React, { Component } from 'react';

import './Cart.scss';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { withTranslation } from 'react-i18next';
import VerifiedIcon from '@material-ui/icons/VerifiedUser';
import VoucherIcon from '@material-ui/icons/CardGiftcard';
import { LinkContainer } from 'react-router-bootstrap';
import { generatePath } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import VoucherForm from 'components/Form/VoucherForm';
import Loader from 'components/Loader';
import CartItem from 'components/Cart/CartItem';
import ToastContent from 'components/ToastContent';
import { logError } from 'services/error-handling/ErrorHandler';
import { handleInternalApiCall, METHOD_GET } from 'services/api-requests/ApiCallHandler';
import LocalStorageService from 'services/storage/LocalStorageService';
import { trackCartChange } from 'services/tracking/TrackingHandler';
import MoneyString from '../MoneyString';

class Cart extends Component {
  constructor(props) {
    super(props);

    const urlParams = queryString.parse(window.location.search);

    this.state = {
      isLoading: false,
      isLoaded: false,
      products: [],
      totalPrice: { currency: '', amount: '' },
      voucher: null,
      showVoucherInput: (typeof urlParams.voucher !== 'undefined'),
    };
  }

  componentDidMount() {
    this.loadCart();
  }

  componentDidUpdate(prevProps) {
    const { update } = this.props;
    if (update !== prevProps.update && update) {
      this.loadCart();
    }
  }

  loadCart = () => {
    const { eventToken, t, onUpdate } = this.props;
    this.setState({ isLoading: true });

    handleInternalApiCall(`/shop/cart/${LocalStorageService.getCartUuid()}/event/${eventToken}`,
      METHOD_GET,
      false,
      null,
      (response) => {
        this.setState({
          isLoading: false,
          isLoaded: true,
          products: response.data.products,
          totalPrice: response.data.totalPrice,
          voucher: response.data.voucher,
          showVoucherInput: false,
        });

        onUpdate(response.data.products, response.data.employee);

        trackCartChange(response.data.products, response.data.totalPrice);
      },
      (error) => {
        logError(error);
        toast.error(<ToastContent type="error" message={t('error.cart.get')} />);
        this.setState({ isLoading: false });
      });
  };

  toggleVoucherClick = () => {
    const { showVoucherInput } = this.state;
    this.setState({
      showVoucherInput: !showVoucherInput,
    });
  };

  render() {
    const {
      image, eventSlug, eventToken, t, i18n,
    } = this.props;
    const {
      isLoading, products, showVoucherInput, voucher, vouchers, isLoaded, totalPrice,
    } = this.state;

    return (
      <Container className="CartWrapper SideCart">
        <div className="CartFill" />
        <div className="Cart">
          <div className="CartHeader">
            <img src={image} alt="" />
            <h2>{t('cart.title')}</h2>
          </div>
          <div className="CartBody">
            {isLoading && <Loader className="CartLoader" />}
            {products.length > 0
              ? (
                <div className={isLoading ? 'CartInner Loading' : 'CartInner'}>
                  <div className="CartContents">
                    {products.map((item) => (
                      <CartItem
                        key={`cartitem-${item.token}`}
                        name={item.name}
                        varietyName={item.varietyName}
                        quantity={item.quantity}
                        originalPrice={item.originalPrice}
                        unitPrice={item.unitPrice}
                        totalPrice={item.totalPrice}
                        discounts={item.discounts}
                        vouchers={item.vouchers}
                      />
                    ))}
                  </div>
                  {voucher != null && (
                    <div className="CartVouchers">
                      {vouchers.map((item) => (
                        <CartItem
                          key={`voucheritem-${item.token}`}
                          voucher
                          name={item.voucher_name}
                          voucher_discount={item.discount}
                        />
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <div className="CartContents">
                  {isLoaded && (
                    <div className="CartEmpty">
                      {t('cart.cart_is_empty')}
                    </div>
                  )}
                </div>
              )}
          </div>
          <div className={isLoading ? 'CartFooter Loading' : 'CartFooter'}>
            {products.length > 0 && (
              <Row className="CartTotal">
                <Col className="CartTotalText" xs={8}>
                  <div className="CartTotalTextTotal">
                    {t('cart.total')}
                  </div>
                  <div className="CartTotalTextDescription">
                    {t('cart.includingVAT')}
                  </div>
                </Col>
                <Col className="CartTotalAmount" xs={4}>
                  <MoneyString
                    amount={totalPrice.amount}
                    currency={totalPrice.currency}
                  />
                </Col>
              </Row>
            )}
            {(!showVoucherInput) && (
              <div className="CartVoucherAdd">
                <Button className="AddVoucherLink" variant="link" onClick={this.toggleVoucherClick}>
                  <VoucherIcon />
                  {' '}
                  {t('cart.addVoucher')}
                </Button>
              </div>
            )}
            {showVoucherInput && (
              <VoucherForm
                loadCart={this.loadCart}
                eventToken={eventToken}
                toggleVoucherClick={this.toggleVoucherClick}
              />
            )}
            <div className={isLoading ? 'CartButtons Loading' : 'CartButtons'}>
              <LinkContainer
                to={`/${i18n.language}${generatePath(t('routes:ticket.cart'),
                  { eventSlug })}`}
              >
                <Button
                  variant="primary"
                  block
                  size="lg"
                  disabled={products.length === 0}
                >
                  {t('event.button.buy_tickets')}
                </Button>
              </LinkContainer>
            </div>

            <div className="SafePayment">
              <VerifiedIcon className="SafePaymentIcon" />
              {' '}
              {t('cart.safePayment')}
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default withTranslation()(Cart);

Cart.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  image: PropTypes.string.isRequired,
  eventSlug: PropTypes.string.isRequired,
  eventToken: PropTypes.string.isRequired,
  update: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
};
