import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import PropTypes from 'prop-types';
import Header from 'components/Header';
import Footer from 'components/Footer';
import FullScreenLoader from 'components/Loader/FullScreenLoader';
import ToastContent from 'components/ToastContent';
import { logError } from 'services/error-handling/ErrorHandler';
import LargeHeader from 'components/LargeHeader';
import './AccountTickets.scss';
import DocumentTitle from 'components/DocumentTitle';
import { handleInternalApiCall, METHOD_GET } from 'services/api-requests/ApiCallHandler';
import PersonalizedTicketItem from 'components/AccountOrderOverview/PersonalizedTicketItem';

class AccountTickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tickets: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    this.getTickets();
  }

  getTickets = () => {
    const { t } = this.props;
    handleInternalApiCall('/me/tickets', METHOD_GET, true, null, (response) => {
      this.setState({
        tickets: response.data,
        isLoading: false,
      });
    }, (error) => {
      logError(error);
      toast.error(<ToastContent type="error" message={t('error.tickets.get')} />);
      this.setState({ isLoading: false });
    });
  };

  render() {
    const { t, match } = this.props;
    const { isLoading, tickets } = this.state;

    if (isLoading === true) return <FullScreenLoader route={match} />;

    return (
      <div className="Account AccountOrders">
        <DocumentTitle title={`Soul Survivor | ${t('menu.tickets')}`} />
        <Header route={match} />
        <LargeHeader title={t('menu.tickets')}>
          <ConfirmationNumberOutlinedIcon className="LargeHeaderInformationIcon" />
          {tickets.length}
          {' '}
          {tickets.length > 1 ? t('account.tickets') : t('account.ticket')}
        </LargeHeader>

        <div className="Main">

          <Container>
            <div className="Block">
              <Row className="OrderItemHeader">
                <Col md={3}>
                  {t('account.table.event')}
                </Col>
                <Col md={2}>
                  {t('account.table.ticketnumber')}
                </Col>
                <Col md={4}>
                  {t('account.table.ticket')}
                </Col>
                <Col md={3}>
                  &nbsp;
                </Col>
              </Row>

              {tickets.map((ticket) => (
                <PersonalizedTicketItem
                  key={ticket.token}
                  ticketToken={ticket.token}
                  ticketNumber={ticket.number}
                  eventName={ticket.eventName}
                  ticketName={ticket.name}
                  fromDate={ticket.validFrom}
                  tillDate={ticket.validTill}
                />
              ))}
            </div>
          </Container>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withTranslation()(AccountTickets);

AccountTickets.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
};

AccountTickets.defaultProps = {
  match: null,
};
