import React from 'react';
import Logo from './logo.svg';
import ApplePay from './payment/applepay.svg';
import Ideal from './payment/ideal.svg';
import Bancontact from './payment/bancontact.svg';
import Visa from './payment/visa.svg';
import AmericanExpress from './payment/amex.svg';
import MasterCard from './payment/mastercard.svg';
import Mollie from './payment/mollie.svg';
import Messenger from './messenger.svg';

export default () => <Logo />;

export const LogoApplePay = () => <ApplePay />;
export const LogoIdeal = () => <Ideal />;
export const LogoBancontact = () => <Bancontact />;
export const LogoVisa = () => <Visa />;
export const LogoAmericanExpress = () => <AmericanExpress />;
export const LogoMasterCard = () => <MasterCard />;
export const LogoMollie = () => <Mollie />;
export const LogoMessenger = () => <Messenger />;
