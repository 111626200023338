import React from 'react';
import PropTypes from 'prop-types';

import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { classNames } from '../../util';
import './Alert.scss';

const Alert = ({
  type, icon, children, style,
}) => {
  let DisplayIcon = InfoIcon;

  if (icon !== null) {
    DisplayIcon = icon;
  } else if (type === 'error') {
    DisplayIcon = ErrorIcon;
  } else if (type === 'warning') {
    DisplayIcon = WarningIcon;
  } else if (type === 'success') {
    DisplayIcon = CheckCircleIcon;
  } else if (type === 'info') {
    DisplayIcon = InfoIcon;
  }

  return (
    <div className={classNames('Alert', type)} style={style}>
      <div className="AlertIcon">
        <DisplayIcon />
      </div>
      <div className="AlertMessage">
        {children}
      </div>
    </div>
  );
};

export default Alert;

Alert.propTypes = {
  type: PropTypes.string.isRequired,
  icon: PropTypes.string,
  children: PropTypes.node.isRequired,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

Alert.defaultProps = {
  icon: null,
  style: null,
};
