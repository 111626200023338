import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { withTranslation } from 'react-i18next';
import { LinkContainer } from 'react-router-bootstrap';
import PropTypes from 'prop-types';
import DocumentTitle from 'components/DocumentTitle';
import Breadcrumbs from 'components/Breadcrumbs';
import SmallCart from 'components/Cart/SmallCart';
import './Details.scss';
import DetailsForm from 'components/Form/DetailsForm';
import CheckoutFooter from 'components/CheckoutFooter';

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasItems: null,
      employee: null,
    };
  }

  updateItems = (hasItems, employee) => {
    this.setState({
      hasItems,
      employee,
    });
  };

  render() {
    const { t, i18n, event } = this.props;
    const { employee, hasItems } = this.state;

    return (
      <div className="Details">
        <DocumentTitle
          title={`Soul Survivor ${event.name} | ${t('checkout.personal.information')}`}
        />

        <Breadcrumbs
          active="details"
          eventSlug={event.slug}
          eventUrl={event.url}
          employee={employee}
        />

        {hasItems === false ? (
          <div className="Block CartEmpty">
            <p>
              {t('cart.cart_is_empty')}
            </p>

            <LinkContainer to={`/${i18n.language}/${event.slug}`}>
              <Button variant="light">
                <ChevronLeftIcon />
                {' '}
                {t('button.back_to_tickets')}
              </Button>
            </LinkContainer>
          </div>
        ) : (
          <Row>
            <Col lg={7} className="CheckOutColumn">
              <div className="DetailsBlock Block">
                <h2>{t('checkout.personal.information')}</h2>
                <div className="BlockExplanation">
                  {t('checkout.personal.subtext')}
                </div>

                <DetailsForm
                  eventSlug={event.slug}
                  eventToken={event.token}
                  eventUrl={event.url}
                  employee={employee}
                />
              </div>
            </Col>
            <Col lg={5} className="CheckOutColumn">
              <div className="CartBlock">
                <SmallCart
                  eventSlug={event.slug}
                  eventToken={event.token}
                  onUpdate={this.updateItems}
                  track
                />
              </div>
            </Col>
          </Row>
        )}

        <CheckoutFooter />
      </div>
    );
  }
}

export default withTranslation()(Details);

Details.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  event: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
    token: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};
